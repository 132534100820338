<template>
    <div>
        <div v-show="isBusy">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
            <p class="md-display-1 text-center mt-4">{{busyMessage}}</p>
        </div>   
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1" v-show="!isBusy">
            <h3 class="md-title" style="flex: 1" >New reservation</h3>
           
            <md-button class="md-raised" @click="validateAllSteps(false)" :disabled="isBusy">Save</md-button>
            <md-button class="md-primary md-raised" @click="validateAllSteps(true)" :disabled="isBusy">Save &amp; Collect</md-button>
           
        </md-toolbar>
        
        <md-tabs 
            class="fixed-tab-selector" 
            md-alignment="left" 
            @md-changed="mainTabChanged" 
            ref="mainTabs" 
            :md-active-tab="mainActiveTab" 
            v-show="!isBusy"
            :md-swipeable="true"
            :class="{ 'hide-desktop-tabs': dialogWidth < 992}"
            
            
            > 
            <template slot="md-tab" slot-scope="{ tab }"> 
                {{ tab.label }} 
                <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
                <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
            </template>
            <md-tab id="date-time-location" md-label="Date &amp; location" :md-template-data="{ badge: tabs.dateTimeLocation.errors }">
                <md-card class="uplimo-card">
                    <md-card-header>
                        <div class="md-title">Date, time &amp; location</div>
                    </md-card-header>
                    <md-card-content>
                        <CRow>
                            <CCol sm="12" md="12" lg="12" xl="6">
                                <CRow>
                                    <CCol sm="12">
                                        <md-field :class="getValidationClass('day')">
                                            <VueCtkDateTimePicker 
                                                v-model="selectedData.datetime" 
                                                :dark="true" 
                                                :inline="false" 
                                                :formatted="'llll (HH:mm [HS])'"
                                                :format="'YYYY-MM-DD hh:mm a'"
                                                :output-format="'X'"
                                                :color="'#448aff'"
                                                :button-color="'#448aff'"	
                                                :minute-interval="5"
                                                id="ul-timepicker"
                                            />
                                        <span class="md-error" v-if="!$v.reservation.day.required">Date &amp; time are required</span>
                                       
                                        

                                        <span class="md-helper-text" v-if="reservation.companyProfile">
                                            Selected date and time below will be applied to  <b>{{reservation.companyProfile.name}}</b> timezone <b>{{reservation.companyProfile.timeZone}}</b> <br><b>Actual date time {{companyActualDateTime}}</b>
                                       </span>

                                        </md-field>
                                        
                                    </CCol>
                                    
                                    
                                </CRow>
                                <CRow class="mt-4" v-if="reservation.vehicleType && reservation.vehicleType.rates.hourlyEnabled && reservation.jobType && reservation.jobType.type == 'Hourly'">
                                    <CCol sm="12">
                                        <md-field>
                                            <label for="hours">hours</label>
                                            <md-select v-model="reservation.time" name="hours" id="hours">
                                                <md-option value="1">1</md-option>
                                                <md-option value="2">2</md-option>
                                                <md-option value="3">3</md-option>
                                                <md-option value="4">4</md-option>
                                                <md-option value="5">5</md-option>
                                                <md-option value="6">6</md-option>
                                                <md-option value="7">7</md-option>
                                                <md-option value="8">8</md-option>
                                                <md-option value="9">9</md-option>
                                                <md-option value="10">10</md-option>
                                                <md-option value="11">11</md-option>
                                                <md-option value="12">12</md-option>
                                            </md-select>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow :class="{ 'mt-5': reservation.companyProfile }"> 
                                    <CCol sm="8">
                                        <md-field :class="getValidationClass('pu')" >
                                            <gmap-autocomplete
                                                :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                
                                                placeholder="Pick up"
                                                @place_changed="setPU" class="pu-autocomplete" @change="getSelectedAddress('pu',$event.target)"   
                                            >
                                            </gmap-autocomplete>
                                            <!--md-button class="md-icon-button" @click="usePlace" >
                                                <md-icon>add</md-icon>
                                            </md-button-->
                                            <span class="md-error" v-if="!$v.reservation.pu.required">Pick up is required</span>
                                        </md-field>

                                    </CCol>

                                        <CCol sm="4">
                                            <md-field>
                                            <label>Zip code</label>
                                            <md-input v-model="reservation.pu.postalCode" readonly></md-input>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12">
                                        <md-button class="md-raised stop-button" @click="addStop"><md-icon>add_location_alt</md-icon>Add stop</md-button>
                                        <!--div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`"-->
                                        <div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`">
                                            <div class="row">
                                                <div  :class="{ 'col-sm-8': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-6': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                    <!--md-field :class="focusedItem == index ? 'md-focused' : ''"-->
                                                    <md-field :class="labelFocusedItems.indexOf(index) > -1 || labelWrittenItems.indexOf(index) > -1 ? 'md-focused' : ''">
                                                        <label>{{'Stop #' + (index + 1)}}</label>
                                                        <gmap-autocomplete
                                                            :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                            :placeholder="''"
                                                            @place_changed="setWP" class="pu-autocomplete"
                                                            @change="autocompleteUser(index,$event.target)"
                                                            @focus="focusedLocation(index)"
                                                            @blur="unfocusLocation(index)"
                                                            v-on:input="inputWithValue(index,$event.target.value)"
                                                            
                                                        >
                                                        </gmap-autocomplete>
                                                        
                                                    </md-field>
                                                </div>
                                                <div class="stop-zip-delete-container" 
                                                      v-if="reservation.jobType && reservation.jobType.type == 'Distance'" 
                                                      :class="{ 'col-sm-0': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                    <md-field>
                                                        <label>Waiting Time (min)</label>
                                                        <md-input v-model="waypoint.waitingTime" type="number" step="15"></md-input>
                                                        
                                                    </md-field>
                                                </div>
                                                <div class="stop-zip-delete-container"
                                                    :class="{ 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-2': reservation.jobType && reservation.jobType.type == 'Distance'}"
                                                >
                                                    <md-field>
                                                        <label>Zip code</label>
                                                        <md-input v-model="waypoint.postalCode" readonly></md-input>
                                                        
                                                    </md-field>
                                                </div>
                                                
                                                <div class="col-sm-1 stop-zip-delete-container">
                                                    <md-button class="md-icon-button" @click="removeWaypoint(index)">
                                                        <md-icon>delete</md-icon>
                                                    </md-button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </CCol>
                                    

                                
                                </CRow>
                                    <CRow>
                                    <CCol sm="8">
                                        <md-field :class="getValidationClass('do')">
                                            <gmap-autocomplete
                                                placeholder="Drop off"
                                                @place_changed="setDO" class="pu-autocomplete"
                                                @change="getSelectedAddress('do',$event.target)">
                                            </gmap-autocomplete>
                                            <span class="md-error" v-if="!$v.reservation.do.required">Drop off is required</span>
                                            
                                        </md-field>
                                        </CCol>

                                        <CCol sm="4">
                                            <md-field>
                                            <label>Zip code</label>
                                            <md-input v-model="reservation.do.postalCode" readonly></md-input>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12" >
                                        <md-field :class="getValidationClass('jobType')">
                                            <md-select v-model="selectedData.jobType" name="vehicle_type" id="job-type" placeholder="Job Type" >
                                                <md-option v-for="serviceType in serviceTypes"  :value="serviceType.id" v-bind:key="serviceType.id">{{serviceType.name}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.reservation.jobType.required">Job Type is required</span>
                                        </md-field>
                                        <md-autocomplete 
                                                :md-opened="operatorSelectorOpened()"
                                                md-input-id="airlines-selector"
                                                :md-options="airlines" 
                                                :md-open-on-focus="true" 
                                                :md-dense="true"
                                                @focusin.native="focusedAirlines($event)"
                                                @md-changed="getAirlines"
                                                @md-selected="setAirline"
                                                :value="reservation.flightAirline"
                                                aria-autocomplete="off"
                                                :class="getValidationClass('flightAirline')"
                                                ref="airlineAutocomplete"
                                                >
                                            <label>Airline</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item }" >
                                                <span class="airline-code">
                                                    <b v-if="item.icao != '' && item.iata != ''">{{ item.icao }} - {{item.iata}}</b>
                                                    <b v-if="item.icao == '' && item.iata != ''">{{item.iata}}</b>
                                                    <b v-if="item.icao != '' && item.iata == ''">{{item.icao}}</b>
                                                </span> 
                                                <span> {{ item.name }}</span>
                                                
                                                
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }"> No matches found for <b>{{ term }}</b> </template>
                                            <span class="md-error" v-if="!$v.reservation.flightAirline.required">Flight Airline is required</span>
                                        </md-autocomplete>
                                        <div v-if="reservation && reservation.flightAirline">
                                            Selected airline <b>
                                                <span v-if="reservation.flightIcao">{{reservation.flightIcao}}</span>
                                                {{ reservation.flightIcao && reservation.flightIata ? ' / ' : '' }}
                                                <span v-if="reservation.flightIata">{{reservation.flightIata}}</span>
                                            </b>
                                        </div> 
                                        <div v-else>No airline selected</div>
                                    </CCol>
                                    <!--CCol sm="6">
                                        <md-field :class="getValidationClass('flightAirline')">
                                            <label>Flight airline code</label>
                                            <md-input v-model="reservation.flightAirline" @keyup="initFlightCheck"></md-input>
                                            <span class="md-error" v-if="!$v.reservation.flightAirline.required">Airline is required</span>
                                            <span class="md-error" v-else-if="!$v.reservation.flightAirline.serverError">Airline is invalid</span>
                                            
                                        </md-field>
                                    </CCol-->
                                    <CCol sm="12">
                                        <md-field :class="getValidationClass('flightNumber')">
                                            <label>Flight #</label>
                                            <md-input v-model="reservation.flightNumber"  @keyup="initFlightCheck" :disabled="!$v.reservation.jobType.required"></md-input>
                                            <span class="md-error" v-if="!$v.reservation.flightNumber.required">Flight is required</span>
                                            <span class="md-error" v-else-if="!$v.reservation.flightNumber.serverError">Flight is invalid</span>
                                            <md-progress-spinner v-show="loadingFlight" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                            
                                            <span class="md-helper-text" v-if="!$v.reservation.jobType.required">Select jobtype to enable this field</span>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <div v-if="reservation.flightPack.noMatch" class="md-elevation-4 p-3">
                                    <div class="md-layout md-alignment-center-center">
                                        <div class="md-layout-item md-size-15">
                                             <md-icon class="md-size-2x  md-accent">airplanemode_inactive</md-icon>
                                        </div>
                                        <div class="md-layout-item">
                                            <div class="md-title"><b>{{reservation.flightIcao != '' && reservation.flightIcao != null ? reservation.flightIcao : reservation.flightIata != null ? reservation.flightIata : ''}}  {{ reservation.flightNumber}}</b></div>
                                            <div class="md-subheading">No matches found</div>
                                            <p class="md-body-2 mb-0">Searched flight code  has not been founded. Please doublecheck date, time, location, airline and flight number</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div v-if="reservation.flightPack.dayLimitExceeded" class="md-elevation-4 p-3">
                                    <div class="md-layout md-alignment-center-center">
                                        <div class="md-layout-item md-size-15">
                                             <md-icon class="md-size-2x  md-primary">update</md-icon>
                                        </div>
                                        <div class="md-layout-item">
                                            <div class="md-title"><b>{{reservation.flightIcao != '' && reservation.flightIcao != null ? reservation.flightIcao : reservation.flightIata != null ? reservation.flightIata : ''}}  {{ reservation.flightNumber}}</b></div>
                                            <div class="md-subheading">Flight data queued</div>
                                            <p class="md-body-2 mb-0">Flight info will be automatically checked 2 days before departure time</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div v-if="reservation.flightPack.schedule" class="md-elevation-4 p-3 mb-4">
                                    <CRow  class="mb-2">
                                        <CCol sm="12">
                                            <div>Matched flight</div>
                                            <div class="md-title">
                                                <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != ''">
                                                    {{ reservation.flightPack.icao }}
                                                </span>
                                                <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != '' && reservation.flightPack.iata != null && reservation.flightPack.iata != ''">/</span>
                                                <span v-if="reservation.flightPack.iata != null && reservation.flightPack.iata != ''">
                                                    {{ reservation.flightPack.iata }}
                                                </span>

                                                {{reservation.flightPack.number}}
                                            </div>
                                            
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="reservation.flightPack.schedule">
                                        
                                        <CCol sm="6">
                                            <md-icon class="mr-2">flight_takeoff</md-icon>
                                            <div class="md-subheading mb-1">Departure</div>
                                            <div class="md-title up-text-primary">{{reservation.flightPack.schedule.origin.code_icao}} <span v-if="reservation.flightPack.schedule.origin.code_iata">/ {{reservation.flightPack.schedule.origin.code_iata }}</span></div>
                                            <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.originAirport.name }}</div>
                                            <div class="md-caption">{{reservation.flightPack.originAirport.city }} / {{reservation.flightPack.originAirport.country }}</div>
                                            <md-divider class="mb-2 mt-2"></md-divider>
                                            <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_out | moment('timezone', reservation.flightPack.originAirport.tz , 'YYYY-MM-DD') }}</div>
                                            <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_out | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A (HH:mm [HS])')  }}</div>
                                            <div class="md-caption">Timezone: {{reservation.flightPack.originAirport.tz }}</div>
                                            <md-divider class="mb-2 mt-2"></md-divider>
                                            <CRow v-if="reservation.flightPack" >
                                                <CCol sm="12">
                                                    <div>Terminal: {{reservation.flightPack.schedule.terminal_origin != '' ? reservation.flightPack.schedule.terminal_origin : '-' }}</div>
                                                    <div>Gate:  {{reservation.flightPack.schedule.gate_origin != '' ? reservation.flightPack.schedule.gate_origin : '-' }}</div>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol sm="6">
                                            <md-icon class="mr-2">flight_land</md-icon>
                                            <div class="md-subheading mb-1">Arrival</div>
                                            <div class="md-title up-text-primary">{{reservation.flightPack.schedule.destination.code_icao}} <span v-if="reservation.flightPack.schedule.destination.code_iata">/ {{reservation.flightPack.schedule.destination.code_iata }}</span></div>
                                            <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.destinationAirport.name }}</div>
                                            <div class="md-caption">{{reservation.flightPack.destinationAirport.city }} / {{reservation.flightPack.destinationAirport.country }}</div>

                                            <md-divider class="mb-2 mt-2"></md-divider>
                                            <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_in | moment('timezone', reservation.flightPack.destinationAirport.tz , 'YYYY-MM-DD') }}</div>
                                            <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_in | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A (HH:mm [HS])') }}</div>
                                             <div class="md-caption">Timezone: {{reservation.flightPack.destinationAirport.tz }}</div>
                                            <md-divider class="mb-2 mt-2"></md-divider>
                                            <CRow v-if="reservation.flightPack" >
                                                <CCol sm="12">
                                                    <div>Terminal: {{reservation.flightPack.schedule.terminal_destination != '' ? reservation.flightPack.schedule.terminal_destination : '-' }}</div>
                                                    <div>Gate:  {{reservation.flightPack.schedule.gate_destination != '' ? reservation.flightPack.schedule.gate_destination : '-' }}</div>
                                                    <div>Bag claim:  {{reservation.flightPack.schedule.baggage_claim != '' ? reservation.flightPack.schedule.baggage_claim : '-' }}</div>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </div>
                                
                                <CRow v-if="reservation.flightInfo">
                                    <CCol sm="12">
                                        <md-table class="flight-table">
                                            <md-table-row>
                                                <md-table-head>Airline</md-table-head>
                                                <md-table-head>Flight</md-table-head>
                                                <md-table-head>Origin</md-table-head>
                                                <md-table-head>Destination</md-table-head>
                                            
                                                <md-table-head>Day</md-table-head>
                                                
                                                <md-table-head v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">ETD</md-table-head>
                                                <md-table-head v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">ETA</md-table-head>
                                            </md-table-row>

                                            <md-table-row>
                                                <md-table-cell>{{reservation.flightAirline}}</md-table-cell>
                                                <md-table-cell>{{reservation.flightInfo.ident}}</md-table-cell>
                                                <md-table-cell>{{reservation.flightInfo.originAirport}} <br><b>{{ reservation.flightInfo.origin }}</b></md-table-cell>
                                                <md-table-cell>{{reservation.flightInfo.destinationAirport}} <br> <b>{{ reservation.flightInfo.destination }}</b></md-table-cell>
                                                <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>

                                                <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>
                                            </md-table-row>
                                        </md-table>
                                    </CCol>    
                                </CRow>
                                <CRow>
                                    <CCol sm="6">
                                        <FeeCalculator 
                                            :addons="feeCalculatorData.addOns"
                                            :promoCodes="reservation.promoCodes"
                                            :pu="reservation.pu" 
                                            :do="reservation.do" 
                                            :datetime="reservation.day"
                                            :jobType="feeCalculatorData.jobType" 
                                            :companyProfile="reservation.companyProfile"
                                            :vehicleType="feeCalculatorData.vehicleType" 
                                            :overviewPath="feeCalculatorData.overviewPath"
                                            :extras="selectedData.extras"
                                            :calculatedDistance="reservation.miles"
                                            :waypoints="reservation.waypoints"
                                            @priceTable="printPriceTable"/> 
                                        <!--md-field>
                                            <label>Price</label>
                                            <md-input v-model="reservation.price"></md-input>
                                        </md-field-->
                                    </CCol>
                                    <CCol sm="6">
                                        
                                        <md-field>
                                            <label>Miles</label>
                                            <md-input v-model="reservation.miles" readonly></md-input>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12">
                                        <md-field>
                                            <label>Promo code</label>
                                            <md-input v-model="feeCalculatorData.promoCode"></md-input>
                                            
                                            <md-progress-spinner v-show="loadingPromoCode" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                        </md-field>
                                        <md-button class="md-raised stop-button" @click="addPromoCode">Add code</md-button>
                                        
                                        <md-list v-if="reservation.promoCodes && reservation.promoCodes.length > 0">
                                            <md-subheader>Codes to check</md-subheader>
                                            <md-list-item v-for="promoCode in reservation.promoCodes" v-bind:key="'codes-to-check-' + promoCode">{{promoCode}}</md-list-item>
                                        </md-list>  
                                    </CCol>
                                </CRow>
                                <CRow v-if="reservation.vehicleType 
                                            && 
                                            (
                                                ( reservation.vehicleType.rates.extras.childSeat.enabled 
                                            && reservation.vehicleType.rates.extras.childSeat.price )
                                            || ( reservation.vehicleType.rates.extras.accessible.enabled 
                                            && reservation.vehicleType.rates.extras.accessible.price )
                                            || ( reservation.vehicleType.rates.extras.meetAndGreet.enabled 
                                            && reservation.vehicleType.rates.extras.meetAndGreet.price)
                                            )">
                                    <CCol sm="12">
                                        <div class="extras-box mt-2">
                                            <div class="ex-title">Extras</div>
                                            <CRow>
                                                <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.childSeat.enabled && reservation.vehicleType.rates.extras.childSeat.price">
                                                    <md-icon  class="special-icon super" v-bind:class="{ 'active-icon': reservation.childSeat }">child_friendly</md-icon> 
                                                    <md-switch v-model="reservation.childSeat">Childseat</md-switch>
                                                </CCol>
                                                <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.accessible.enabled && reservation.vehicleType.rates.extras.accessible.price">
                                                    <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.accessible }">accessible</md-icon> 
                                                    <md-switch v-model="reservation.accessible">Accessible</md-switch>
                                                </CCol>
                                                <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.meetAndGreet.enabled && reservation.vehicleType.rates.extras.meetAndGreet.price">
                                                    <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.meetAndGreet }">face</md-icon> 
                                                    <md-switch v-model="reservation.meetAndGreet">Meet and greet</md-switch>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </CCol>
                                </CRow>
                                
                            </CCol>
                            
                            <CCol sm="12" md="12" lg="12" xl="6">
                                <GmapMap :center="{lat:coordinates.lat,lng:coordinates.lng}" :zoom="4" style="width:100%;height:600px">
                                    <Gmap-Marker v-for="(marker, index) in markers"
                                        :key="index"
                                        :position="marker.position"
                                        ></Gmap-Marker>
                                    

                                    <DirectionsRenderer
                                        travelMode="DRIVING"
                                        :origin="origin"
                                        :destination="destination"
                                        :waypoints="computedWaypoints"
                                        @updated-miles="updatedMiles"
                                        @overview-path="overviewPath"
                                        ref="road" />
                                </GmapMap>
                            </CCol>
                        
                        </CRow>
                    </md-card-content>
                </md-card>
            </md-tab>
            <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
                <CRow>
                    <CCol sm="12" lg="6">
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">General</div>
                            </md-card-header>
                            <md-card-content>
                                        <md-field :class="getValidationClass('companyProfile')">
                                            <md-select v-model="selectedData.companyProfile" name="company_profile" id="company-profile" placeholder="Company" >
                                                <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.reservation.companyProfile.required">Company is required</span>
                                        </md-field>
                                        
                                        <md-field :class="getValidationClass('vehicleType')">
                                            <md-select  v-model="selectedData.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" :disabled="!$v.reservation.jobType.required">
                                                <md-option v-for="vehicleType in filteredVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.reservation.vehicleType.required">Vehicle Type is required</span>
                                        </md-field>
                                        
                                        
                                        
                                        <CRow>
                                            <CCol sm="6">
                                                <md-field :class="getValidationClass('qty')">
                                                    <label># Passengers</label>
                                                    <md-input v-model="reservation.passenger.qty" type="number" :max="selectedData.passengers" :disabled="!selectedData.passengers"></md-input>
                                                    <span class="md-error" v-if="!$v.reservation.passenger.qty.required">Passenger quantity is required</span>
                                                    <span class="md-error" v-if="!$v.reservation.passenger.qty.between">Number must be between 1 and {{selectedData.passengers}}</span>
                                                </md-field>
                                                
                                            </CCol>
                                            <CCol sm="6">
                                                <md-field :class="getValidationClass('luggage')">
                                                    <label># Luggage</label>
                                                    <md-input v-model="reservation.passenger.luggage" type="number" :max="selectedData.luggage" :disabled="!selectedData.luggage"></md-input>
                                                    <span class="md-error" v-if="!$v.reservation.passenger.luggage.required">luggage quantity is required</span>
                                                    <span class="md-error" v-if="!$v.reservation.passenger.luggage.between">Number must be between 0 and {{selectedData.luggage}}</span>

                                                </md-field>
                                            </CCol>
                                        </CRow>
                                        
                                        <CRow>
                                            <CCol sm="6">
                                                <md-field>
                                                    <label>Notes for passenger</label>
                                                    <md-textarea v-model="reservation.notes[0].text" md-autogrow md-counter="300"></md-textarea>
                                                </md-field>
                                                
                                                
                                            </CCol>
                                            <CCol sm="6">
                                                <md-field>
                                                    <label>Notes for driver</label>
                                                    <md-textarea v-model="reservation.notes[1].text" md-autogrow md-counter="300"></md-textarea>
                                                </md-field>
                                                
                                            </CCol>
                                            
                                        </CRow>
                                        <CRow>
                                            <CCol sm="12">
                                                <md-field>
                                                    <label>Notes for all</label>
                                                    <md-textarea v-model="reservation.notes[2].text" md-autogrow md-counter="300"></md-textarea>
                                                </md-field>
                                            </CCol>
                                        </CRow>
                                        
                                    
                            </md-card-content>
                        </md-card>
                    </CCol>
                            
                </CRow>
            </md-tab>     
            <md-tab id="dispatchment-info" md-label="Dispatchment" :md-template-data="{ badge: tabs.dispatchmentInfo.errors }">
                <CRow>
                    <CCol sm="12" lg="6">
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">Dispatchment info</div>
                            </md-card-header>
                            <md-card-content>
                                <CRow>
                                    <CCol sm="6">
                                        <!--md-field :class="getValidationClass('dispatcher')">
                                            <md-select v-model="selectedData.dispatcher" name="dispatcher" id="dispatcher" placeholder="Dispatcher">
                                                <md-option v-for="dispatcher in dispatchers" :value="dispatcher.uid" v-bind:key="dispatcher.uid">{{dispatcher.displayName}}</md-option>
                                            </md-select>
                                                <span class="md-error" v-if="!$v.reservation.dispatcher.required">Dispatcher is required</span>
                                        </md-field-->
                                        <!--md-autocomplete :value="reservation.dispatcher" :md-options="dispatchers" :class="getValidationClass('dispatcher')" @md-selected="setDispatcher">
                                            <label>Dispatcher</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <span> {{ item.displayName }}</span> 
                                                
                                            </template>
                                            <span class="md-error" v-if="!$v.reservation.dispatcher.required">Dispatcher is required</span>
                                        </md-autocomplete-->
                                        
                                    </CCol>
                                
                                    <CCol sm="12">        
                                        <md-field>
                                            <label>How long in advance (minutes)</label>
                                            <md-input v-model="reservation.publishAnticipation" type="number"></md-input>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12">
                                        <md-field>
                                            <label>Driver assignations status</label>
                                            <md-select v-model="reservation.driverStatus" name="driver_status" id="driver_status" placeholder="Driver assignation status">
                                                <md-option v-for="status in driverStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                                            </md-select>
                                        </md-field>
                                        <div v-if="assignationBox">
                                            <md-switch v-model="listAssignation">Select from list</md-switch>
                                            <md-field v-if="listAssignation">
                                                <label>Select driver</label>
                                                <md-select v-model="reservation.assignedDriver">
                                                    <md-option v-for="driver in drivers" v-bind:key="driver.uid" :value="driver.email">{{driver.displayName}} | {{driver.email}}</md-option>
                                                </md-select>
                                            </md-field>
                                            <md-field v-else>
                                                <label>Type driver email</label>
                                                <md-input v-model="reservation.assignedDriver" type="email"></md-input>
                                            </md-field>
                                        </div>
                                         <md-field>
                                            <label>Driver amount ($)</label>
                                            <md-input v-model="reservation.driverAmount" type="number"></md-input>
                                        </md-field>

                                        <md-field :class="getValidationClass('reservationStatus')">
                                            <label>Reservation status</label>
                                            <md-select v-model="reservation.reservationStatus" name="reservation_status" id="reservation_status" placeholder="Reservation status">
                                                <md-option v-for="status in reservationStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.reservation.reservationStatus.required">Reservation status required</span>
                                        </md-field>
                                        <md-field >
                                             <label>Trip status</label>
                                            <md-select v-model="reservation.tripStatus" @md-selected="updateReservation(item)">
                                                <md-option v-for="tripStatus in tripStatuses" :value="tripStatus.id" v-bind:key="tripStatus.id">{{tripStatus.name}}</md-option>
                                            </md-select>
                                        </md-field>
                                        
                                    </CCol>
                                </CRow>
                            </md-card-content>
                        </md-card>
                    </CCol>
                            
                </CRow>
            </md-tab>
            <md-tab id="addons" md-label="Add ons" :md-template-data="{ badge: tabs.addons.errors }">
                <CRow>
                    <CCol sm="12" lg="6">
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">Addons</div>
                            </md-card-header>
                            <md-card-content>
                                <md-field v-if="reservation.vehicleType && reservation.vehicleType.rates.addonsAvailable">
                                    
                                    <md-table>
                                        <md-table-row>
                                            <md-table-head>Name</md-table-head>
                                            <md-table-head>Qty</md-table-head>
                                            <md-table-head>Price</md-table-head>
                                            <md-table-head>Subtotal</md-table-head>
                                        </md-table-row>
                                        <template v-for="addon in reservation.vehicleType.rates.addons">
                                            <md-table-row v-if="addon.enabled && addon.price" v-bind:key="'reservation-' + addon.id">

                                                <md-table-cell>{{addon.name}}
                                                </md-table-cell>

                                                 <md-table-cell>
                                                     <md-field >
                                                        <label>Addon quantity</label>
                                                        <md-input type="number" v-model="addon.qty" min="0"></md-input>
                                                    </md-field>
                                                </md-table-cell>

                                                 <md-table-cell>{{addon.price}}
                                                </md-table-cell>
                                                
                                                 <md-table-cell>
                                                     {{ addon.qty ? parseFloat(addon.qty) * parseFloat(addon.price) : 0}}
                                                </md-table-cell>

                                            </md-table-row>
                                            
                                        </template>
                                    </md-table>
                                    
                                </md-field>
                                <md-empty-state
                                    v-else
                                    class="mt-4 md-primary"
                                    md-rounded
                                    md-icon="sentiment_dissatisfied"
                                    md-label="No addons available"
                                    md-description="No addons available for selected vehicle">
                                </md-empty-state>
                            </md-card-content>
                        </md-card>
                    </CCol>
                   
                </CRow>
            </md-tab>
            <md-tab id="rates-and-taxes" md-label="Rates" :md-template-data="{ badge: tabs.ratesAndTaxes.errors }" >
                <md-empty-state
                    v-if="!rateData"
                    class="mt-4 md-primary"
                    md-rounded
                    md-icon="request_quote"
                    md-label="Calculated rates"
                    md-description="Rate detail will be displayed here when required information is completed">
                </md-empty-state>
                
                <md-card class="uplimo-card" v-if="rateData">
                    <md-card-header>
                        <div class="md-title">Rate details</div>
                    </md-card-header>
                    <md-card-content>
                        <md-table>
                            <md-table-row>
                                <md-table-head>Reference</md-table-head>
                                <md-table-head class="text-right">Units, minutes or %</md-table-head>
                                <md-table-head class="text-right">$ cost per unit</md-table-head>
                                <md-table-head class="text-right">$ Subtotal No Tax</md-table-head>
                                <md-table-head class="text-right">$ Subtotal + Tax</md-table-head>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell colspan="5" class="bg-black">Ride related</md-table-cell>
                                
                            </md-table-row>
                            <md-table-row v-if="rateData.activeFlatRate">
                                <md-table-cell>{{rateData.activeFlatRate.name}} (Flat rate)</md-table-cell>
                                <md-table-cell class="text-right">1</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.activeFlatRate.price}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.activeFlatRate.price}}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="rateData.miniumRate && !rateData.activeFlatRate">
                                <md-table-cell>Minuim rate</md-table-cell>
                                <md-table-cell class="text-right">1</md-table-cell>
                                <md-table-cell class="text-right">{{reservation.vehicleType.rates.miniumRate}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{reservation.vehicleType.rates.miniumRate}}</md-table-cell>
                            </md-table-row>
                            <template v-if="!rateData.activeFlatRate && !rateData.miniumRate">
                                <md-table-row v-for="(rate,index) in rateData.rateSpread" v-bind:key="index">
                                    <md-table-cell v-if="rate.base"> Base rate <b>(The first {{rateData.rates.base.includedMiles}} miles)</b></md-table-cell>
                                    <md-table-cell v-else>Miles</md-table-cell>

                                    <md-table-cell class="text-right"> {{ rate.base ? '1' : roundNumber(rate.milesQty)}}</md-table-cell>
                                    <md-table-cell class="text-right">{{rate.base ? roundNumber(rate.rate) : rate.ratePerMile }}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rate.rate)}}</md-table-cell>    
                                </md-table-row>
                            </template>
                            
                            
                              
                            <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                <md-table-cell>Stops</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.stops.length}}</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.stop}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.subtotals.stops}}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                <md-table-cell>Total waiting time on stops</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.subtotals.waitingTimeMinutesTotal}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{roundNumber(rateData.subtotals.waitingTimePriceTotal)}}</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell><b>Subtotal</b></md-table-cell>
                                <md-table-cell class="text-right">-</md-table-cell>
                                <md-table-cell class="text-right">-</md-table-cell>
                                <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterRideDetails)}}</b></md-table-cell>
                            </md-table-row>

                            <!-- Increases and decreases here -->
                            <template v-if="rateData.validOverrides.length > 0">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Increases and decreases</md-table-cell>
                                    
                                </md-table-row>
                                <template  >
                                    <md-table-row v-for="override in rateData.validOverrides" v-bind:key="override.id">
                                        <md-table-cell>{{override.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.value + '%' : 1}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterOverrides)}}</b></md-table-cell>
                                </md-table-row>
                        
                            </template>

                            <!-- Bonus and discounts here -->
                            <template v-if="rateData.validPromoCodes.length > 0">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Bonus and discounts</md-table-cell>
                                    
                                </md-table-row>
                                <template  >
                                    <md-table-row v-for="promoCode in rateData.validPromoCodes" v-bind:key="promoCode.id">
                                        <md-table-cell>{{promoCode.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.value + '%' : 1}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterDiscount)}}</b></md-table-cell>
                                </md-table-row>
                        
                            </template>

                            <!-- Applied on base tax here -->
                            <template v-if="rateData.subtotals.appliedOnBase">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Applied on base</md-table-cell>
                                </md-table-row>
                                <template v-for="tax in rateData.subtotals.onBaseTaxList">
                                    <md-table-row v-if="tax.type === 'percentage' && tax.percentageType === 'base'" v-bind:key="tax.id">
                                        <md-table-cell>{{tax.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100)}}</md-table-cell>
                                        <md-table-cell class="text-right">{{!tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                    </md-table-row>
                                </template>
                                
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable)}}</b></md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseTaxable)}}</b></md-table-cell>
                                </md-table-row>

                            </template>

                            <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Addons and miscellaneous</md-table-cell>
                                </md-table-row>
                            </template>
                            <template v-if="rateData.addons && rateData.addons.length > 0">
                                <md-table-row v-for="addon in rateData.addons" v-bind:key="addon.id">
                                    <md-table-cell>{{addon.name}}</md-table-cell>
                                    <md-table-cell class="text-right">{{addon.qty}}</md-table-cell>
                                    <md-table-cell class="text-right">{{addon.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{addon.price * addon.qty}}</md-table-cell>
                                </md-table-row>
                            </template>
                            <md-table-row v-if="rateData.extras.accessible">
                                <md-table-cell>Accessible</md-table-cell>
                                <md-table-cell class="text-right">1</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="rateData.extras.childSeat">
                                <md-table-cell>Child Seat</md-table-cell>
                                <md-table-cell class="text-right">1</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="rateData.extras.meetAndGreet">
                                <md-table-cell>Meet and Greet</md-table-cell>
                                <md-table-cell class="text-right">1</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                                <md-table-cell class="text-right">0.00</md-table-cell>
                                <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                            </md-table-row>
                            <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable) }}</b>   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAddonsTaxable) }}</b></md-table-cell>
                                </md-table-row>
                            </template>

                            <template v-if="rateData.subtotals.fixedTaxes > 0">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Tolls and fees</md-table-cell>
                                </md-table-row>
                            </template>
                            <template v-if="rateData.subtotals.fixedTaxes > 0">
                                <template v-for="tax in rateData.taxes">
                                <md-table-row  v-if="tax.type === 'fixed'" v-bind:key="tax.id">
                                    <md-table-cell>{{tax.name}}</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                    <md-table-cell class="text-right">{{tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                    <md-table-cell class="text-right">{{!tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                </md-table-row>
                                </template>
                            </template>
                            <template v-if="rateData.subtotals.fixedTaxes > 0">
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesNoTaxable) }}</b>   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesTaxable) }}</b></md-table-cell>
                                </md-table-row>
                            </template>
                          
                           
                            <template>
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Applied on total tax</md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Sale tax</md-table-cell>
                                    <md-table-cell class="text-right">{{ reservation.companyProfile.saleTax }}%</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                </md-table-row>
                            </template>
                            <template >
                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterSaleTaxNoTaxable) }}</b>   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterSaleTaxTaxable)}}</b></md-table-cell>
                                </md-table-row>
                            </template>
                            <md-table-row>
                                <md-table-cell>Credit card fee</md-table-cell>
                                <md-table-cell class="text-right">{{ reservation.companyProfile.creditCardFee }}%</md-table-cell>
                                <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}} / {{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                                <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}}</md-table-cell>
                                <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                            </md-table-row>
                            <template>
                                <md-table-row class="final-rows uplimo-blue">
                                    <md-table-cell><b>Subtotals</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.lastNoTaxable)}}</b>   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.lastTaxable)}}</b></md-table-cell>
                                </md-table-row>
                            </template>
                            <template>
                                <md-table-row class="final-rows uplimo-blue">
                                    <md-table-cell><b>Total</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                </md-table-row>
                            </template>
                            <template>
                                <md-table-row class="final-rows uplimo-green">
                                    <md-table-cell><b>Amount to collect</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-   
                                    </md-table-cell>
                                   <md-table-cell class="text-right">
                                        <div v-switch="payment.paymentMethod">
                                            <b v-case="'cash'">
                                            {{ payment.cash.paidAmount }}
                                            </b>
                                            <b  v-case="'credit-card'">
                                                {{ cardToPayAmount }}
                                            </b>
                                        </div>
                                     </md-table-cell>
                                </md-table-row>
                            </template>
                            <template>
                                <md-table-row class="final-rows uplimo-yellow">
                                    <md-table-cell><b>Balance</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ payment.cash.paidAmount && reservation.paymentMethod == 'cash' ? roundNumber(rateData.price - payment.cash.paidAmount) : roundNumber(rateData.price) }}</b></md-table-cell>
                                </md-table-row>
                            </template>
                            <!--template>
                                <md-table-row class="final-rows uplimo-red">
                                    <md-table-cell><b>Collect Balance</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-   
                                    </md-table-cell>
                                    <md-table-cell class="text-right"><b>{{ payment.cash.paidAmount && reservation.paymentMethod == 'cash' ? roundNumber(rateData.price - payment.cash.paidAmount) : roundNumber(rateData.price) }}</b></md-table-cell>
                                </md-table-row>
                            </template-->
                           
                            
                            <!--template v-if="rateData.subtotals.onTotalTaxList.length > 0">
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Applied on total tax</md-table-cell>
                                </md-table-row>
                            </template>
                            <template v-if="rateData.subtotals.onTotalTaxList.length > 0">
                                <template v-for="tax in rateData.subtotals.onTotalTaxList">
                                    <md-table-row v-if="tax.type === 'percentage' && tax.percentageType === 'total'" v-bind:key="tax.id">
                                        <md-table-cell>{{tax.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(parseFloat(tax.value) * rateData.subtotals.priceTotalNoTaxes / 100)}}</md-table-cell>
                                        <md-table-cell class="text-right">{{!tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.priceTotalNoTaxes / 100) : '0.00'}}</md-table-cell>
                                        <md-table-cell class="text-right">{{ tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.priceTotalNoTaxes / 100) : '0.00'}}</md-table-cell>
                                    </md-table-row>
                                </template>
                               
                            </template-->
                            


                            

                        </md-table>
                    </md-card-content>
                </md-card>

                
                
            </md-tab>
            <md-tab id="passenger" md-label="Passengers" :md-template-data="{ badge: tabs.passenger.errors }">
                <CRow>
                    
                    <CCol sm="12" md="8" >
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">Passengers</div>
                            </md-card-header>
                            <md-card-content>
                                <md-table v-model="reservation.passengers" v-if="reservation.passengers.length > 0">
                                    <md-table-row slot="md-table-row" slot-scope="{ item , index }">
                                        <md-table-cell md-label="Name">{{item.firstName}}
                                            <md-chip class="md-primary ml-2" v-if="!item.id">New</md-chip>
                                        </md-table-cell>
                                        <md-table-cell md-label="Lastname">{{item.lastName}}</md-table-cell>
                                        <md-table-cell md-label="Email">{{item.email}}</md-table-cell>
                                        <md-table-cell md-label="Phone">{{item.phone}}</md-table-cell>
                                        <md-table-cell md-label="Actions">
                                            <md-button class="md-fab md-mini ml-0" @click="removeAddedPassenger(item, index)">
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                                <md-empty-state
                                        v-else
                                        md-icon="person_off"
                                        :md-label="`No passengers added yet`"
                                        >
                                        <div :class="getValidationClass('passengers')" class="md-field md-theme-default no-line min-height-initial">
                                            <div class="md-error md-subheading mt-2 text-center right-0" v-if="!$v.reservation.passengers.required">Add at least one passenger</div>
                                        </div>
                                    </md-empty-state>
                                <md-button class="md-raised md-primary full-width-button" @click="firePassengerDialog()" v-if="availableClientPassengers > 0 || availableClientPassengers == null">Add passenger</md-button>
                                <md-button class="md-raised md-primary full-width-button" v-if="reservation.client" @click="fireNonRegisteredPass()">Add non registered passenger</md-button>
                                
                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="4" >
                        <md-card class="uplimo-card" v-if="reservation.client">
                            <md-card-header>
                                <div class="md-title">Client</div>
                            </md-card-header>
                            <md-card-content>
                                <div class="md-caption">Name</div>
                                <div class="md-subheading mb-3">
                                    {{ reservation.client.type === 'corporate' ? reservation.client.name : '' }}
                                    {{ reservation.client.type === 'individual' ? `${reservation.client.firstName} ${reservation.client.lastName}` : '' }}
                                    
                                </div>
                                <div class="md-caption">Phone</div>
                                <div class="md-subheading mb-3">
                                    {{ reservation.client.phone }}
                                </div>
                                <div class="md-caption">Email</div>
                                <div class="md-subheading mb-3">
                                    {{ reservation.client.email }}
                                </div>
                                <div class="md-caption">Type</div>
                                <div class="md-subheading mb-3">
                                    {{ reservation.client.type }}
                                </div>
                                <md-button class="md-raised md-accent full-width-button" @click="removeSelectedClient()">Remove selected client</md-button>
                                <div class="md-caption">Selected passengers will also be removed</div> 
                            </md-card-content>
                        </md-card>
                    </CCol>
                    
                            
                </CRow>
            </md-tab>
            <md-tab id="payment-method" md-label="Payment" :md-template-data="{ badge: tabs.paymentMethod.errors }">
                <CRow>
                    <CCol sm="12" md="12" lg="6">
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">Payment method</div>
                            </md-card-header>
                            <md-card-content>
                                <div class="amount-to-pay" v-show="reservation.price">
                                    <div class="title">Amount to pay</div>
                                    <div class="amount">$ {{reservation.price}}</div>
                                </div>
                               
                                <!--md-field v-show="reservation.paymentMethod === 'cash' || reservation.paymentMethod === 'check'">
                                    <label>Payment status</label>
                                    <md-select v-model="reservation.paymentStatus" name="payment_status" id="payment_status" placeholder="Payment status">
                                        <md-option v-for="status in paymentStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                                    </md-select>
                                </md-field-->
                                <p v-if="merchantAccounts.length == 0">There is no merchant account avtive. Create new merchant account or activate an existing one in  <a @click="goToMerchant()"> Merchant Accounts</a> </p>
                                <md-field :class="getValidationClass('merchantAccount')" v-else>
                                    <label>Merchant accounts</label>
                                    <md-select v-model="payment.merchantAccount" name="merchant_account" id="merchant_account" placeholder="Merchant account">
                                        <md-option v-for="merchant in merchantAccounts" :value="merchant.id" v-bind:key="merchant.id">{{merchant.name}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="!$v.payment.merchantAccount.required">Merchant account is required</span>
                                </md-field>
                                
                                <div class="row">
                                    <div class="col-sm-3">
                                        <md-radio v-model="payment.paymentMethod" value="credit-card">Credit card</md-radio>
                                    </div>
                                    <div class="col-sm-3">
                                        <md-radio v-model="payment.paymentMethod" value="cash">Cash</md-radio>
                                    </div>
                                    <div class="col-sm-3">
                                        <md-radio v-model="payment.paymentMethod" value="check">Check</md-radio>
                                    </div>
                                    <div class="col-sm-3">
                                        <md-radio v-model="payment.paymentMethod" value="invoice">Invoice</md-radio>
                                    </div>
                                </div>
                                <md-field :class="getValidationClass('paymentMethod')" v-if="!$v.payment.paymentMethod.required">
                                    <span class="md-error" v-if="!$v.payment.paymentMethod.required">Payment method is required</span>
                                </md-field>
                                    
                                
                                
                                
                                 <div >   
                                    <div  v-show="payment.paymentMethod == 'cash'" md-label="cash">
                                        <md-field :class="getValidationClass('paidAmount')">
                                            <md-icon>account_balance_wallet</md-icon>
                                            <label>Amount to collect</label>
                                            <md-input 
                                                    type="number"
                                                    v-model="payment.cash.paidAmount"   
                                            ></md-input>
                                            <span class="md-error" v-if="!$v.payment.cash.paidAmount.required">Amount to collect is required</span>
                                        </md-field>
                                    </div>
                                    <div v-show="payment.paymentMethod == 'credit-card'" md-label="Credit card" >
                                       
                                        <!--div v-if="braintreeToken">
                                            <hosted-fields
                                                wrapperClass="constrain"
                                                :authToken="braintreeToken" 
                                                v-on:bthferror="onBraintreeError" 
                                                v-on:bthfpayload="onBraintreeSuccess" 
                                                :collectCardHolderName="false"
                                                :collectPostalCode="false" 
                                                :enableDataCollector="false" 
                                                ref="hosted"
                                            ></hosted-fields>
                                            
                                        </div-->
                                        <md-field>
                                                <label>Amount to collect</label>
                                                <md-input 
                                                        type="number"
                                                        v-model="cardToPayAmount"   
                                                ></md-input>
                                        </md-field>
                                        
                                       
                                        <!--------->
                                        <div >
                                            <div v-if="payment.gateway == 'US Bank Account'">
                                                <div id="card-form">
                                                    <div class="textfield--float-label">
                                                        <!-- Begin hosted fields section -->
                                                        <label class="hosted-field--label" for="number"><span class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg></span> Card Number 
                                                        </label>
                                                        <div id="number" class="hosted-field"></div>
                                                        <!-- End hosted fields section -->
                                                    </div>
                                                    <div class="textfield--float-label">
                                                        <!-- Begin hosted fields section -->
                                                        <label class="hosted-field--label" for="cardHolder">
                                                        <md-icon class="icon">person_outline</md-icon>
                                                            Card holder 
                                                        </label>
                                                        <div id="cardholder" class="hosted-field"></div>
                                                        <!-- End hosted fields section -->
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                
                                                            <div class="textfield--float-label">
                                                                <!-- Begin hosted fields section -->
                                                                <label class="hosted-field--label" for="expiration-date">
                                                                <span class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/></svg>
                                                                </span>
                                                                Expiration Date</label>
                                                                <div id="expiration-date" class="hosted-field"></div>
                                                                <!-- End hosted fields section -->
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="textfield--float-label">
                                                                <!-- Begin hosted fields section -->
                                                                <label class="hosted-field--label" for="cvv">
                                                                <span class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
                                                                    </span>
                                                                    CVV</label>
                                                                <div id="cvv" class="hosted-field"></div>
                                                                <!-- End hosted fields section -->
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="textfield--float-label">
                                                                <!-- Begin hosted fields section -->
                                                                <label class="hosted-field--label" for="postal-code">
                                                                <span class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
                                                                </span>
                                                                Postal Code</label>
                                                                <div id="postal" class="hosted-field"></div>
                                                                <!-- End hosted fields section -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div v-else-if="payment.gateway == 'Paypal PayFlow Pro'">
                                                <vue-paycard 
                                                    :value-fields="payment.cardFields" 
                                                    :input-fields="inputFields" 
                                                    :is-card-number-masked="false" 
                                                    class="mb-5"/>
                                                <md-field :class="getValidationClass('cardNumber')" :md-counter="false" style="margin-top:40px">
                                                    <md-icon>credit_card</md-icon>
                                                    <label>Credit card number</label>
                                                    <md-input  type="tel"
                                                            :value="payment.cardFields.cardNumber"
                                                            @keyup="changeNumber"
                                                            data-card-field
                                                            autocomplete="off"
                                                            :id="inputFields.cardNumber"
                                                            :maxlength="cardNumberMaxLength"
                                                            ref="cardNumber"
                                                            
                                                            
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.cardFields.cardNumber.required">Card number is required</span>
                                                </md-field>

                                                <md-field :class="getValidationClass('cardName')">
                                                    <md-icon>person_outline</md-icon>
                                                    <label>Name on card</label>
                                                    <md-input  type="text"
                                                            :id="inputFields.cardName"
                                                                title="Name on card"
                                                                v-letter-only
                                                                :value="payment.cardFields.cardName"
                                                                @keyup="changeName"
                                                                data-card-field
                                                                autocomplete="off"
                                                                ref="cardName"
                                                            
                                                            
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.cardFields.cardName.required">Card name is required</span>
                                                </md-field>
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <md-field class="expiration-month" :class="[getValidationClass('cardMonth'),getValidationClass('cardYear')]">
                                                            <md-icon>event</md-icon>
                                                            <label>Expiration date</label>
                                                            <md-select md-dense 
                                                                        :id="inputFields.cardMonth"
                                                                        aria-label="Card Month"
                                                                        title="Month"
                                                                        v-model="payment.cardFields.cardMonth"
                                                                        data-card-field
                                                                        ref="cardMonth"
                                                                        
                                                            >
                                                                <md-option value disabled selected>Month</md-option>
                                                                <md-option
                                                                    v-bind:value="n < 10 ? '0' + n : n"
                                                                    v-for="n in 12"
                                                                    v-bind:disabled="n < minCardMonth"
                                                                    v-bind:key="n"
                                                                >{{generateMonthValue(n)}}</md-option>
                                                                
                                                            </md-select>
                                                            <md-select md-dense 
                                                                        :id="inputFields.cardYear"
                                                                        aria-label="Card year"
                                                                        title="Year"
                                                                        v-model="payment.cardFields.cardYear"
                                                                        data-card-field
                                                                        ref="cardYear"
                                                                        
                                                            >
                                                                <md-option value disabled selected>Year</md-option>
                                                                <md-option
                                                                    v-bind:value="$index + minCardYear"
                                                                    v-for="(n, $index) in 12"
                                                                    v-bind:key="n"
                                                                >{{$index + minCardYear}}</md-option>
                                                                
                                                            </md-select>
                                                            <span class="md-error" v-if="!$v.payment.cardFields.cardMonth.required || !$v.payment.cardFields.cardYear.required">Card expiration is required</span>
                                                            
                                                        </md-field>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <md-field :md-counter="false" :class="getValidationClass('cardCvv')">
                                                            <md-icon>https</md-icon>
                                                            <label>CVV</label>
                                                            <md-input 
                                                                        type="tel"
                                                                        title="CVV"
                                                                        v-number-only
                                                                        :id="inputFields.cardCvv"
                                                                        maxlength="4"
                                                                        :value="payment.cardFields.cardCvv"
                                                                        @keyup="changeCvv"
                                                                        data-card-field
                                                                        autocomplete="off"
                                                                        ref="cardCvv"
                                                                    
                                                                    
                                                            ></md-input>
                                                            <span class="md-error" v-if="!$v.payment.cardFields.cardCvv.required">Card CVV is required</span>
                                                        </md-field>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <md-field :class="getValidationClass('billZip')">
                                                            <md-icon>home</md-icon>
                                                            <label>Bill Zip code</label>
                                                            <md-input 
                                                                    type="tel"
                                                                    title="Zip code"
                                                                    v-number-only
                                                                    v-model="payment.cardFields.billZip"   
                                                            ></md-input>
                                                            <span class="md-error" v-if="!$v.payment.cardFields.billZip.required">Billing ZIP is required</span>
                                                        </md-field>
                                                    </div>
                                                </div>
                                                <md-field :class="getValidationClass('billingAddress')">
                                                    <md-icon>map</md-icon>
                                                    <label>Billing address</label>
                                                    <md-input 
                                                            title="Billing address"
                                                            v-model="payment.cardFields.billingAddress"
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.cardFields.billingAddress.required">Billing address is required</span>
                                                </md-field>
                                            </div>
                                            <div v-else-if="payment.gateway == 'Stripe'">
                                                <form id="payment-form">
                                                    <div id="payment-element">
                                                        <!-- Elements will create form elements here -->
                                                    </div>
                                                    <!--button id="submit">Submit</button-->
                                                    <div id="error-message">
                                                        <!-- Display error message to your customers here -->
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        
                                        <!--div v-show="hostedFieldsInstance">
                                            <md-field :class="getValidationClass('cardNumber')" :md-counter="false" style="margin-top:40px">
                                                <md-icon>credit_card</md-icon>
                                                <label>Credit card number</label>
                                                <span class="input-field" id="number"></span>
                                               
                                                <span class="md-error" v-if="!$v.payment.cardFields.cardNumber.required">Card number is required</span>
                                            </md-field>


                                           
                                           
                                            <label for="cardholder">Card Holder
                                            <div  class="input-field" id="cardholder" ></div>
                                            </label>
                                          

                                            <label for="expiration-date">Expiration Date
                                                <div class="input-field" id="expiration-date"></div>
                                            </label>

                                            <label for="cvv">CVV
                                                <div class="input-field" id="cvv"></div>
                                            </label>

                                            <label for="postal" >Postal Code
                                                <div type="text" class="input-field" id="postal"></div>
                                            </label>
                                           

                                          
                                            <md-field :class="getValidationClass('billingAddress')">
                                                <md-icon>map</md-icon>
                                                <label>Billing address</label>
                                                <md-input 
                                                        title="Billing address"
                                                        v-model="payment.cardFields.billingAddress"
                                                ></md-input>
                                                <span class="md-error" v-if="!$v.payment.cardFields.billingAddress.required">Billing address is required</span>
                                            </md-field>

                                                
                                           

                                        </div-->
                                        <!--md-button @click="submitTransaction">Submit</md-button-->
                                        <!--------->
                                        
                                        <!--md-field :class="getValidationClass('billingAddress')">
                                            <md-icon>map</md-icon>
                                            <label>Billing address</label>
                                            <md-input 
                                                    title="Billing address"
                                                    v-model="payment.cardFields.billingAddress"
                                            ></md-input>
                                            <span class="md-error" v-if="!$v.payment.cardFields.billingAddress.required">Billing address is required</span>
                                        </md-field-->
                                        
                                    </div>
                                    <!-- Check    -Company Name, -Address, -Routing #, -Account # -->
                                    <div v-show="payment.paymentMethod == 'check'" md-label="check">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                 <md-field :class="getValidationClass('check.companyName')">
                                                    <md-icon>business</md-icon>
                                                    <label>Company name</label>
                                                    <md-input 
                                                            v-model="payment.check.companyName"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.check.companyName.required">Company name is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('check.address')">
                                                    <md-icon>room</md-icon>
                                                    <label>Address</label>
                                                    <md-input 
                                                            v-model="payment.check.address"   
                                                    ></md-input>
                                                   <span class="md-error" v-if="!$v.payment.check.address.required">Address is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('check.routing')">
                                                    <md-icon>account_balance</md-icon>
                                                    <label>Routing #</label>
                                                    <md-input 
                                                            v-model="payment.check.routing"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.check.routing.required">Routing # is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('check.account')">
                                                    <md-icon >account_balance</md-icon>
                                                    <label>Account #</label>
                                                    <md-input 
                                                            v-model="payment.check.account"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.check.account.required">Account # is required</span>
                                                </md-field>

                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div v-show="payment.paymentMethod == 'invoice'" md-label="invoice">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                 <md-field :class="getValidationClass('invoice.companyName')">
                                                    <md-icon>business</md-icon>
                                                    <label>Company name</label>
                                                    <md-input 
                                                            v-model="payment.invoice.companyName"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.invoice.companyName.required">Company name is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('invoice.address')">
                                                    <md-icon>room</md-icon>
                                                    <label>Address</label>
                                                    <md-input 
                                                            v-model="payment.invoice.address"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.invoice.address.required">Address is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('invoice.ein')">
                                                    <md-icon>account_balance</md-icon>
                                                    <label>EIN #</label>
                                                    <md-input 
                                                            v-model="payment.invoice.ein"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.invoice.ein.required">EIN is required</span>
                                                </md-field>
                                                 <md-field :class="getValidationClass('invoice.email')">
                                                    <md-icon>mail</md-icon>
                                                    <label>Email</label>
                                                    <md-input 
                                                            v-model="payment.invoice.email"   
                                                    ></md-input>
                                                    <span class="md-error" v-if="!$v.payment.invoice.email.required">Email is required</span>
                                                </md-field>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 
                                
                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="12" lg="6" v-if="rateData">
                        <md-card class="uplimo-card">
                            <md-card-header>
                                <div class="md-title">Rate resume</div>
                            </md-card-header>

                            <md-card-content>
                                <md-table>
                                    <template>
                                        <md-table-row class="final-rows uplimo-blue">
                                            <md-table-cell><b>Total</b></md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-   
                                            </md-table-cell>
                                            <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <template>
                                        <md-table-row class="final-rows uplimo-green">
                                            <md-table-cell><b>Amount to collect</b></md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-   
                                            </md-table-cell>
                                            <md-table-cell class="text-right">
                                                <div v-switch="payment.paymentMethod">
                                                    <b v-case="'cash'">
                                                    {{ payment.cash.paidAmount }}
                                                    </b>
                                                    <b  v-case="'credit-card'">
                                                        {{ cardToPayAmount }}
                                                    </b>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <template>
                                        <md-table-row class="final-rows uplimo-yellow">
                                            <md-table-cell><b>Balance</b></md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-</md-table-cell>
                                            <md-table-cell class="text-right">-   
                                            </md-table-cell>
                                            <md-table-cell class="text-right"><b>{{ payment.cash.paidAmount && payment.paymentMethod == 'cash' ? roundNumber(rateData.price - payment.cash.paidAmount) : roundNumber(rateData.price) }}</b></md-table-cell>
                                        </md-table-row>
                                    </template>
                                   
                                </md-table>
                            </md-card-content>
                        </md-card>
                    </CCol>
                </CRow>
            </md-tab>
            <md-tab id="emails" md-label="Emails" :md-template-data="{ badge: tabs.emails.errors }">
                <md-toolbar>
                    <div style="flex: 1">
                        <span class="md-title"  @click="reservation.confirmationEmails.send = !reservation.confirmationEmails.send">Send notification emails</span>
                    </div> 
                    <md-switch v-model="reservation.confirmationEmails.send"></md-switch>
                </md-toolbar>
                <transition name="fade">
                    
                
                    <div v-if="reservation.confirmationEmails.send">
                        <md-toolbar class="md-primary" md-elevation="0">
                            <p class="mb-0"><md-icon class="mr-2">announcement</md-icon>Check who will receive each email type</p>
                        </md-toolbar>
                        <CRow class="mt-3">
                            <CCol sm="12" md="6">
                                
                        
                                <md-list >
                                    <md-subheader>Confirmation email</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.confirmation.passenger.send" @change="uncheckQuotation($event)" />
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.confirmation.owner.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.companyProfile ? reservation.companyProfile.mailList : 'Owner email'}}</span>
                                            <span class="md-caption">Owner</span>
                                        </div>
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.confirmation.dispatcher.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{user.data ? user.data.email : ''}} <b> (me)</b></span>
                                            <span class="md-caption">Creator</span>
                                        </div>
                                    </md-list-item>
                                </md-list>
                            </CCol>
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Receipt email</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.receipt.passenger.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                </md-list>
                            </CCol>
                        </CRow>
                        <CRow class="mt-3">
                            
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Driver assignation email</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.driverAssignation.passenger.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.driverAssignation.owner.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.companyProfile ? reservation.companyProfile.mailList : 'Owner email'}}</span>
                                            <span class="md-caption">Owner</span>
                                        </div>
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.driverAssignation.driver.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver email'}}</span>
                                            <span class="md-caption">Driver</span>
                                        </div>
                                    </md-list-item>
                                </md-list>
                            </CCol>
                       
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Quotation email</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationEmails.quotation.passenger.send" @change="quotationChanged"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                </md-list>
                            </CCol>
                        </CRow>
                    </div>
                </transition>
                <md-empty-state
                    v-if="!reservation.confirmationEmails.send"
                    class="mt-4 md-primary"
                    md-rounded
                    md-icon="schedule_send"
                    md-label="Email notifications"
                    md-description="You can save your reservation and send all type of emails notifications later on">
                </md-empty-state>
            </md-tab>
             <md-tab id="sms" md-label="SMS" :md-template-data="{ badge: tabs.sms.errors }">
                <md-toolbar>
                    <div style="flex: 1">
                        <span class="md-title"  @click="reservation.confirmationSMS.send = !reservation.confirmationSMS.send">Send notification SMS</span>
                    </div> 
                    <md-switch v-model="reservation.confirmationSMS.send"></md-switch>
                </md-toolbar>
                <transition name="fade">
                    
                
                    <div v-if="reservation.confirmationSMS.send">
                        <md-toolbar class="md-primary" md-elevation="0">
                            <p class="mb-0"><md-icon class="mr-2">announcement</md-icon>Check who will receive each SMS type</p>
                        </md-toolbar>
                        <CRow class="mt-3">
                            <CCol sm="12" md="6">
                                
                        
                                <md-list >
                                    <md-subheader>Confirmation sms</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.confirmation.passenger.send" @change="uncheckQuotation($event)" />
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger sms'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.confirmation.owner.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.companyProfile ? reservation.companyProfile.smsPhoneTarget : 'Owner sms'}}</span>
                                            <span class="md-caption">Owner</span>
                                        </div>
                                    </md-list-item>
                                   
                                </md-list>
                            </CCol>
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Receipt sms</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.receipt.passenger.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger sms'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                </md-list>
                            </CCol>
                        </CRow>
                        <CRow class="mt-3">
                            
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Driver assignation sms</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.driverAssignation.passenger.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger sms'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.driverAssignation.owner.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.companyProfile ? reservation.companyProfile.smsPhoneTarget : 'Owner sms'}}</span>
                                            <span class="md-caption">Owner</span>
                                        </div>
                                    </md-list-item>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.driverAssignation.driver.send" @change="uncheckQuotation($event)"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver sms'}}</span>
                                            <span class="md-caption">Driver</span>
                                        </div>
                                    </md-list-item>
                                </md-list>
                            </CCol>
                       
                            <CCol sm="6">
                                <md-list >
                                    <md-subheader>Quotation sms</md-subheader>
                                    <md-list-item>
                                        <md-checkbox v-model="reservation.confirmationSMS.quotation.passenger.send" @change="quotationChanged"/>
                                        <div class="md-list-item-text">
                                            <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger sms'}}</span>
                                            <span class="md-caption">Passenger</span>
                                        </div>
                                        
                                    </md-list-item>
                                </md-list>
                            </CCol>
                        </CRow>
                    </div>
                </transition>
                <md-empty-state
                    v-if="!reservation.confirmationSMS.send"
                    class="mt-4 md-primary"
                    md-rounded
                    md-icon="schedule_send"
                    md-label="Email notifications"
                    md-description="You can save your reservation and send all type of emails notifications later on">
                </md-empty-state>
            </md-tab>
        </md-tabs>
        <transition name="fade">
            <div class="mobile-backdrop-overlay" v-show="showSelector && dialogWidth < 992" @click="showSelector = false"></div>
        </transition>
        <transition name="fade">
            <md-toolbar class="md-dense bottom-fixed extra-case md-elevation-5" v-show="showSelector && dialogWidth < 992" :style="[dialogDimensions]">
                <div class="md-toolbar-row">
                    <md-list>
                        <md-list-item 
                            :class="{ 'active-item-tab' : activeTabIndex == index }" 
                            v-for="(step,index) in steps" 
                            v-bind:key="'selector-mobile' + step.id" 
                            @click="changeMobileTab(step.id, index)">
                            
                            <span class="md-list-item-text">{{ step.label }}</span>
                            
                            <md-badge class="md-accent" :md-content="tabs[step.tabErrorLabel].errors" v-if="tabs[step.tabErrorLabel].errors" />
                            <md-badge class="confirmed" md-content="OK" v-if="tabs[step.tabErrorLabel].errors == 0" />
                        </md-list-item>
                    </md-list>
                </div>
            </md-toolbar>
        </transition>
        <transition name="fade">
            <md-toolbar class="md-dense bottom-fixed" v-show="dialogWidth < 992" :style="[dialogDimensions]">
                
                <div class="md-toolbar-row">
                    <h3 class="md-title flex-1">{{ activeTabLabel }}</h3>
                    <md-button class="md-icon-button" @click="prevTab()" :disabled="activeTabIndex == 0">
                        <md-icon>arrow_back_ios</md-icon>
                    </md-button>
                    <md-button class="md-icon-button" @click="nextTab()" :disabled="activeTabIndex + 1 == this.steps.length">
                        <md-icon>arrow_forward_ios</md-icon>
                    </md-button>
                    <md-button class="md-icon-button" @click="showSelector = !showSelector" >
                        <md-icon>more_vert</md-icon>
                    </md-button>
                </div>
            </md-toolbar>
        </transition>

        <md-dialog
            :style="[dialogDimensions]"
            :md-active.sync="confirmDialog"
        >
            <md-dialog-title>What would you like to do ?</md-dialog-title>
            <md-dialog-content>
                <p>Reservation is not created yet . Choose your action </p>
                <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save</md-button>
            </md-dialog-content>

            
            
        </md-dialog>
        <md-dialog :md-active.sync="showDialog" :style="[dialogDimensions]">
            <md-dialog-title>Before you end</md-dialog-title>
            <!-- Content -->
            <div class="up-modal-content">
                
                
            </div>   
            <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false; isBusy = false">Close</md-button>
                <md-button class="md-primary" @click="add">Save</md-button>
            </md-dialog-actions>
        </md-dialog> 
        <md-dialog :md-active.sync="passengerDialog" :style="[dialogDimensions]" class="scrollable-content-dialog maxium-width-dialog">
            <md-dialog-title>Add passengers</md-dialog-title>
            <div class="up-modal-content">
                
                <md-steppers md-vertical :md-active-step.sync="addPassengerStep" :md-description="clientHasAccount == 'on' ? 'YES' : 'NO'">
                    <md-step id="client-has-account" md-label="Does client has account?" v-if="!reservation.client" :md-error="clientHasAccountProp.error" :md-done.sync="clientHasAccountProp.done" :md-editable="clientHasAccountProp.editable">
                        <md-switch v-model="clientHasAccount" class="md-primary" value="on">Yes</md-switch>
                        <md-switch v-model="clientHasAccount" value="off">No</md-switch>
                        
                    </md-step>
                    <md-step id="client-autocomplete-selector" :md-label="clientHasAccount === 'on' ? 'Find client account' : 'Fill new client info'" v-if="(clientHasAccount == 'on' || clientHasAccount == 'off') && !reservation.client " :md-error="findClientAccount.error" :md-done.sync="findClientAccount.done" :md-editable="findClientAccount.editable">
                        <form autocomplete="off" v-if="clientHasAccount === 'on'"> 
                            <md-autocomplete 
                                
                                md-input-id="client-selector"
                                :md-options="clients" 
                                :md-open-on-focus="false" 
                                :md-dense="true"
                                
                                @md-changed="clientChanged"
                                @md-selected="setClient"
                                
                                :value="clientText"
                                aria-autocomplete="off"
                                :class="getValidationClass('clients')"
                                ref="clientAutocomplete"
                                
                                >
                                <label>Type phone, client name, lastname etc.</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }" >
                                    
                                    <md-highlight-text :md-term="term">{{ item.type === 'corporate' ? item.name : `${item.firstName} ${item.lastName}`}} {{ item.phone ? `(${item.phone})` : ''}} | {{item.type}}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }"> No matches found for <b>{{ term }}</b> </template>
                                
                            </md-autocomplete>
                        </form>

                        <div v-else-if="clientHasAccount === 'off'">
                            <p class="md-subheading">Client will be saved after reservation is created.</p>


                            
                            <p class="md-subheading margin-top-40">Client type</p>
                            <md-switch v-model="newClient.type" class="md-primary" value="corporate">Corporate</md-switch>
                            <md-switch v-model="newClient.type" value="individual">Individual</md-switch>
                            <md-field :class="getValidationClass('newClient.type')" class="no-line reset-height">
                                <span class="md-error" v-if="!$v.newClient.type.required">Type is required</span>
                            </md-field>

                            <md-field :class="getValidationClass('newClient.email')">
                                <label>Email</label>
                                <md-input v-model="newClient.email" @keyup="fireClientCheck()"></md-input>

                                <span class="md-error" v-if="!$v.newClient.email.required">Email is required</span>
                                <span class="md-error" v-else-if="!$v.newClient.email.email">Invalid email</span>
                                <span class="md-error" v-else-if="clientExsits">You have a client under this email <a @click="goToClient()">View Client</a> or change email</span>
                                <span class="md-error" v-else-if="userCheck && (!isPassenger) && newClient.type === 'individual'">This user already has an account but not passenger role available . Ask for another mail</span>
                                <span class="md-error" v-else-if="userCheck && (!isClient) && newClient.type === 'corporate'">This user already has an account but not client role available . Ask for another mail</span>

                            </md-field>
                            <md-field :class="getValidationClass('newClient.name')" v-if="newClient.type === 'corporate'">
                                <label>Company Name</label>
                                <md-input v-model="newClient.name" :disabled="(isClient ) && typeof userCheck === 'object'"></md-input>
                                <span class="md-error" v-if="!$v.newClient.name.required">Name is required</span>
                            </md-field>
                            <md-field :class="getValidationClass('newClient.firstName')"  v-if="newClient.type === 'individual'">
                                <label>Name</label>
                                <md-input v-model="newClient.firstName" :disabled="(isPassenger || isClient ) && typeof userCheck === 'object'"></md-input>
                                <span class="md-error" v-if="!$v.newClient.firstName.required" >Name is required</span>
                            </md-field>
                            <md-field :class="getValidationClass('newClient.lastName')"  v-if="newClient.type === 'individual'">
                                <label>Lastname</label>
                                <md-input v-model="newClient.lastName" :disabled="(isPassenger || isClient ) && typeof userCheck === 'object'"></md-input>
                                <span class="md-error" v-if="!$v.newClient.lastName.required">Lastname is required</span>
                            </md-field>
                            <md-field :class="getValidationClass('newClient.phone')">
                                <label>Phone</label>
                                <md-input v-model="newClient.phone" :disabled="(isPassenger || isClient ) && typeof userCheck === 'object'"></md-input>
                                <span class="md-error" v-if="!$v.newClient.phone.required">Phone is required</span>
                            </md-field>
                            <md-field>
                                <label>Additional Phone</label>
                                <md-input v-model="newClient.additionalPhone" :disabled="newClient.checked"></md-input>
                            </md-field>
                            
                            <div v-if="newClient.type === 'individual'">
                                <p class="md-subheading margin-top-40">Will <b>{{ newClient.firstName ? newClient.firstName : ''}} {{ newClient.lastName ? newClient.lastName : '' }}</b> be a passenger ?</p>
                                <md-switch v-model="newClient.willBePassenger" class="md-primary" value="on">Yes</md-switch>
                                <md-switch v-model="newClient.willBePassenger" value="off">No</md-switch>
                                <md-field :class="getValidationClass('newClient.willBePassenger')" class="no-line reset-height">
                                    <span class="md-error" v-if="!$v.newClient.willBePassenger.required">Select if added person will be a passenger</span>
                                </md-field>
                            </div>
                        </div>
                        <md-button class="md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-has-account'">Previous step</md-button>
                        <md-button class="md-primary md-raised md-dense mt-4" v-if="clientHasAccount === 'off'" @click="fireNewClientValidation()">Next step</md-button>
                    </md-step>
                    <md-step id="passengers-add" md-label="Add passengers to new client account" v-if="clientHasAccount === 'off'">
                        <div class="table-magic-wrapper mt-4">
                            <transition name="fixed-last-col">
                                <table v-if="fixedLastRowActive" class="fixed-last-col-table">
                                    <tr class="md-table-row">
                                        <th class="md-table-head">
                                            <div class="md-table-head-container">
                                                <div class="md-table-head-label">Actions</div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="md-table-row" v-for="(row,index) in fixedLastColRows" v-bind:key="'row-col' + index" :style="`height:${row.height}px`">

                                        <td class="md-table-cell">
                                            <div class="md-table-cell-container">
                                                
                                                <md-button class="ml-0 md-primary md-raised md-dense" @click="addPassengerToNewClient()" v-if="index == fixedLastColRows.length - 1">
                                                   Add passenger
                                                </md-button>
                                                <md-button class="ml-0 md-icon-button" v-else @click="removeNewClientPassenger(index)" :disabled="newClient.passengers[index].willBePassenger">
                                                    <md-icon>delete</md-icon>
                                                </md-button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </transition>
                            <md-table ref="passengerAddTable" class="take-back">
                                <md-table-row>
                                    <md-table-head>Name</md-table-head>
                                    <md-table-head>Lastname</md-table-head>
                                    <md-table-head>Email</md-table-head>
                                    <md-table-head>Phone</md-table-head>
                                    <md-table-head>Actions</md-table-head>
                                </md-table-row>
                                <md-table-row class="content-row" v-for="(item,index) in newClient.passengers" v-bind:key="`new-client-pass-${index}`">
                                    
                                  
                                    <md-table-cell>{{ item.firstName }}
                                        <md-chip class="md-primary ml-2" v-if="item.willBePassenger">Default</md-chip>
                                    </md-table-cell>
                                    <md-table-cell>{{ item.lastName }}</md-table-cell>
                                    <md-table-cell>{{ item.email ? item.email : 'Not provided'}}</md-table-cell>
                                    <md-table-cell>{{ item.phone }}</md-table-cell>
                                    <md-table-cell>
                                        <md-button class="ml-0 md-icon-button" @click="removeNewClientPassenger(index)" :disabled="item.willBePassenger">
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row class="content-row">
                                    
                                    <md-table-cell>
                                        <md-field :class="getValidationClass('newPassengerAddition.firstName')">
                                            <label>Name</label>
                                            <md-input v-model="newPassengerAddition.firstName" ></md-input>
                                            <span class="md-error" v-if="!$v.newPassengerAddition.firstName.required">Name is required</span>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-field :class="getValidationClass('newPassengerAddition.lastName')">
                                            <label>Lastname</label>
                                            <md-input v-model="newPassengerAddition.lastName" ></md-input>
                                            <span class="md-error" v-if="!$v.newPassengerAddition.lastName.required">Lastname is required</span>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-field :class="getValidationClass('newPassengerAddition.email')">
                                            <label>Email</label>
                                            <md-input v-model="newPassengerAddition.email" ></md-input>
                                            <span class="md-error" v-if="!$v.newPassengerAddition.email.email">Invalid email</span>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-field :class="getValidationClass('newPassengerAddition.phone')">
                                            <label>Phone</label>
                                            <md-input v-model="newPassengerAddition.phone" ></md-input>
                                            <span class="md-error" v-if="!$v.newPassengerAddition.phone.required">Phone is required</span>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-button class="ml-0 md-primary md-raised md-dense" @click="addPassengerToNewClient()">
                                           Add passenger
    
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>


                        <md-button class="md-primary md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-autocomplete-selector'">Previous step</md-button>
                        
                    </md-step>
                    <md-step id="passengers-selector" md-label="Select passengers" v-if="clientHasAccount === 'on' && preselected.client">
                        <md-table class="mt-4" v-if="preselected.client && preselected.client.passengers.length > 0" v-model="preselected.client.passengers" md-card @md-selected="passengersSelected">                          

                            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                                <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>
                            </md-table-toolbar>

                            <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select :md-disabled="item.alreadySelected">
                                <md-table-cell md-label="Name" md-sort-by="firstName">
                                    {{ item.firstName }} 
                                    <md-chip v-if="item.alreadySelected" class="ml-2 uplimo-yellow mini mb-0">Already selected</md-chip>
                                </md-table-cell>
                                <md-table-cell md-label="Name" md-sort-by="lastName">{{ item.lastName }}</md-table-cell>
                                <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-button class="md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-autocomplete-selector'" v-if="!reservation.client">Previous step</md-button>

                    </md-step>
                </md-steppers>
            </div>   
            <md-dialog-actions>
                <md-button class="md-raised" @click="passengerDialog = false; isBusy = false">Close</md-button>
                <md-button class="md-raised md-primary" @click="addPassenger()" v-if="clientHasAccount === 'on'">{{ (preselected.client && !preselected.passengers) || ( preselected.passengers && preselected.passengers.length == 0) ? 'Confirm client and add passengers later' : 'Confirm'  }}</md-button>
                <md-button class="md-raised md-primary" @click="addPassenger()" v-if="clientHasAccount === 'off'">{{ newClient.passengers.length > 0 ? 'Confirm' : 'Confirm client data and add passengers later'}}</md-button>
            </md-dialog-actions>
            
        </md-dialog> 
        <md-dialog :md-active.sync="newClientPassengerDialog.active" :style="[dialogDimensions]">
            <md-dialog-title>Add non registered passenger</md-dialog-title>
            
            <div class="up-modal-content">
                <p class="md-subheading">Passenger will be saved into clients account after reservation is created.</p>
                <md-field :class="getValidationClass('dialog.email')">
                    <label>Email</label>
                    <md-input v-model="newClientPassengerDialog.email" ></md-input>
                    <span class="md-error" v-if="!$v.newClientPassengerDialog.email.required">Email is required</span>
                    <span class="md-error" v-if="!$v.newClientPassengerDialog.email.email">Invalid email</span>

                </md-field>
                <md-field :class="getValidationClass('dialog.firstName')" >
                    <label>Name</label>
                    <md-input v-model="newClientPassengerDialog.firstName" :disabled="newClientPassengerDialog.checked"></md-input>
                    <span class="md-error" v-if="!$v.newClientPassengerDialog.firstName.required">Name is required</span>
                </md-field>
                <md-field :class="getValidationClass('dialog.lastName')">
                    <label>Lastname</label>
                    <md-input v-model="newClientPassengerDialog.lastName" :disabled="newClientPassengerDialog.checked"></md-input>
                    <span class="md-error" v-if="!$v.newClientPassengerDialog.lastName.required">Lastname is required</span>
                </md-field>
                <md-field :class="getValidationClass('dialog.phone')">
                    <label>Phone</label>
                    <md-input v-model="newClientPassengerDialog.phone" :disabled="newClientPassengerDialog.checked"></md-input>
                    <span class="md-error" v-if="!$v.newClientPassengerDialog.phone.required">Name is required</span>
                </md-field>
                <md-field>
                    <label>Additional Phone</label>
                    <md-input v-model="newClientPassengerDialog.additionalPhone" :disabled="newClientPassengerDialog.checked"></md-input>
                </md-field>
                <md-button  class="md-raised md-primary full-width-button" @click="addNonRegisteredPassenger()">Add passenger</md-button>
            </div>
            <md-dialog-actions>

            </md-dialog-actions>

        </md-dialog>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import DirectionsRenderer from './../maps/DirectionsRenderer';
import { validationMixin } from 'vuelidate'
import FeeCalculator from './FeeCalculator.vue';

import HostedFields from 'vue-braintree-hosted-fields'

import {
    between,
    required,
    email,
    minLength,
    //maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
import { brandSet } from '@coreui/icons';
import { VuePaycard } from 'vue-paycard'

import axiosApi from "./../../helpers/axios";
import axios from "axios"
//import moment from 'moment'


import moment from 'moment-timezone'
import moment_timezone from "moment-timezone";
import TheFooterVue from '../../containers/TheFooter.vue';
import TheSidebarVue from '../../containers/TheSidebar.vue';
import {loadStripe} from '@stripe/stripe-js';

export default {
    name: 'NewReservation',
    mixins: [validationMixin],
    components: {
        DirectionsRenderer,
        VueTimepicker,
        VuePaycard,
        FeeCalculator,
        HostedFields
        
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                event.target.value = event.target.value.replace(/[^0-9]/g, '')
                if (event.charCode >= 48 && event.charCode <= 57) {
                    return true
                }
                event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                if (event.charCode >= 48 && event.charCode <= 57) {
                    event.preventDefault()
                }
                return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    data() {
        return {
            activeTabIndex: 0,
            activeTabLabel: 'Date, time and location',
            steps: [    
                        { id: 'date-time-location', label: 'Datime and location' , tabErrorLabel: 'dateTimeLocation'} ,
                        { id: 'general', label: 'General' , tabErrorLabel: 'general'} ,
                        { id: 'dispatchment-info', label: 'Dispatchment info' , tabErrorLabel: 'dispatchmentInfo'} ,
                        { id: 'addons', label: 'Addons' , tabErrorLabel: 'addons'} ,
                        { id: 'rates-and-taxes', label: 'Rates and taxes' , tabErrorLabel: 'ratesAndTaxes'} ,
                        { id: 'passenger', label: 'Passenger' , tabErrorLabel: 'passenger'} ,
                        { id: 'payment-method', label: 'Payment method' , tabErrorLabel: 'paymentMethod'} ,
                        { id: 'emails', label: 'Emails' , tabErrorLabel: 'emails'} ,
                        { id: 'sms', label: 'SMS' , tabErrorLabel: 'sms'} ,
                    ] ,
            showSelector: false,
            isEditing: false,
            confirmDialog: false,
            isClient: null,
            isPassenger: null,
            userCheck: null,
            existingClient: null,
            checkingExistantClient:false,
            clientExsits: false,
            checkingUser: false,

            fixedLastColRows: [],
            fixedLastRowActive: true,
            clientHasAccountProp: {
                editable: true,
                done: false,
                error: null
            },
            findClientAccount:{
                editable: false,
                done: false,
                error: null
            },
            selectPassengers: {
                editable: true,
                done: false,
                error: null
            },
            fillNewClientInfo: {
                editable: true,
                done: false,
                error: null
            },
            addPassengerToNewClientProp: {
                editable: true,
                done: false,
                error: null
            },
            newClient: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                passengers: [],
                willBePassenger: null,
                type:null,
            },
            newPassengerAddition: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
            },

            newClientPassengerDialog: {
                active: false,
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null

            },
            preselected: {
                client: null,
                passengers: null
            },
            passengerDialog: false,
            guest: true,
            passenger: {
                guest: true,
                firstName: '',
                lastName:'',
                email:'',
                phone:'',
                additionalPhone: '',
                qty: null,
                luggage:null
            },
            passengerInput: null,
            clientInput: null,
            filteredPassengers: [],
            filteredClients: [],
            //clients:[],
            clientHasAccount: null,
            addPassengerStep: 'client-has-account',
            possibleFlights: [],
            payment: {
                cash: {
                    paidAmount: null
                },
                event: null,
                invoice: {
                    companyName: null,
                    address: null,
                    ein: null,
                    email: null
                },
                check: {
                    companyName: null,
                    address: null,
                    routing: null,
                    account: null
                },
                cardFields: {
                    cardName: '',
                    cardNumber: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                    billZip: '',
                    billingAddress: '',
                },
                merchantAccount: null,
                paymentMethod: 'credit-card',
                //paymentMethod: null,
                collect:false,
                elements: null,
                merchantInstance: null,
                paymentIntent: null,
                clientSecret: null,
                paymentIntentObject: null
            },
            paymentIntentRequest: null,
            cardToPayAmount: null,
            
            tokenizePayload:null,
            hostedFieldsInstance: null,
            clientInstance: null,
            braintreeToken: null,
            assignationBox: false,
            listAssignation: true,
            isBusy: false,
            busyMessage: null,
            companyActualDateTime: null,
            timezoneOffsetDif: null,
            rateData: null,
            mainActiveTab: 'date-time-location',
            tabs: {
                general: {
                    errors: null,
                },
                dateTimeLocation: {
                    errors: null,
                },
                dispatchmentInfo: {
                    errors: null,
                },
                addons: {
                    errors: null,
                },
                ratesAndTaxes: {
                    errors: null,
                },
                passenger: {
                    errors: null,
                },
                paymentMethod: {
                    errors: null,
                },  
                emails: {
                    errors: null,
                },
                sms: {
                    errors: null,
                }
            },
            filteredVehicleTypes: [],
            filteredAddons:[],
            selectedData: {
                companyProfile: null,
                datetime:null,
                jobType: null,
                vehicleType: null,
                passengers:null,
                luggage:null,
                dispatcher:null,
                addOns: [],
                extras: {
                    childSeat: null,
                    accessible: null,
                    meetAndGreet: null
                }
            },
            feeCalculatorData: {
                jobType: {
                    name: null,
                    type: null
                },
                vehicleType: {
                    
                },
                overviewPath:[],
                addOns:[],
                extras: null,
                promoCode:null
                
            },
            loadingFlight: false,
            loadingPromoCode: false,
            serverError: true,
            airlines: [],
            airlinesList: this.$airlines,
            airportsList: this.$airports,
            inputFields: {
                cardNumber: 'v-card-number',
                cardName: 'v-card-name',
                cardMonth: 'v-card-month',
                cardYear: 'v-card-year',
                cardCvv: 'v-card-cvv'
            },
            minCardYear: new Date().getFullYear(),
            mainCardNumber: '',
            cardNumberMaxLength: 19,
            dialogLeft: 0,
            step: "general",
            labelWrittenItems: [],
            labelFocusedItems: [],
            waypointsPack: [],
            activeStopIndex: null,
            actualStopAddress: null,
            date: new Date(),
            //drivers: [],
            addOns: [],
            //serviceTypes: [],
            //reservationStatuses: [],
            //driverStatuses: [],
            //paymentStatuses: [],
            tripStatuses: [],
            reservation: {
                transactions: [],
                gateway:null,
                addOns: [],
                // cash: {
                //     paidAmount: null
                // },
                // invoice: {
                //     companyName: null,
                //     address: null,
                //     ein: null,
                //     email: null
                // },
                // check: {
                //     companyName: null,
                //     address: null,
                //     routing: null,
                //     account: null
                // },
                //paymentMethod: 'credit-card',
                //merchantAccount: null,
                // cardFields: {
                //     cardName: '',
                //     cardNumber: '',
                //     cardMonth: '',
                //     cardYear: '',
                //     cardCvv: '',
                //     billZip: '',
                //     billingAddress: '',
                // },
                readableId: null,
                dispatcher:null,
                day: null,
                do: {
                    formatted_address: '',
                    postalCode: null,
                    coordinates: {
                        lat: null,
                        lng: null
                    }
                },
                pu: {
                    formatted_address: '',
                    postalCode: null,
                    coordinates: {
                        lat: null,
                        lng: null
                    }
                },
                waypoints: [],
                miles: null,
                price: null,
                generatedPrice: null,
                passenger: {
                    
                    qty: null,
                    luggage:null
                },
                passengers: [],
                client: null,
                
                passengers: [],
                promoCodes:[],
                time: null,
                vehicleType: null,
                companyProfile: null,
                jobType: null,
                flightNumber: null,
                flightAirline: null,
                flightCode: null,
                flightInfo: null,
                flightIcao: null,
                flightIata:null,
                //flightIdentMatch:null,
                flightPack: {
                    icao: null,
                    iata: null,
                    schedule: null,
                    info: null,
                    number:null,
                    noMatch: false,
                    originAirport: null,
                    destinationAirport: null,

                },
                childSeat: false,
                accessible: false,
                meetAndGreet: false,
                publishAnticipation: 0,
                sms: [],
                mails: [],
                confirmationEmails: {
                    send: false,
                    confirmation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        dispatcher: {
                            send: false
                        },
                       
                    },
                    receipt: {
                        passenger: {
                            send: false,
                        },
                        
                    },
                    cancellation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        dispatcher: {
                            send: false
                        },
                        driver: {
                            send: false
                        },
                        
                    },
                    driverAssignation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        driver: {
                            send: false
                        },
                        
                    },
                    refund: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        
                    },
                    quotation: {
                        passenger: {
                            send: false,
                        },
                        
                    }
                    
                },
                confirmationSMS: {
                    send: false,
                    confirmation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        
                       
                    },
                    receipt: {
                        passenger: {
                            send: false,
                        },
                        
                    },
                    cancellation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        dispatcher: {
                            send: false
                        },
                        driver: {
                            send: false
                        },
                        
                    },
                    driverAssignation: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        driver: {
                            send: false
                        },
                        
                    },
                    refund: {
                        passenger: {
                            send: false,
                        },
                        owner: {
                            send: false
                        },
                        
                    },
                    quotation: {
                        passenger: {
                            send: false,
                        },
                        
                    }
                    
                },
                notes: [
                    {
                        type: 'passenger',
                        label: 'Passenger',
                        text: null
                    },
                    {
                        type: 'driver',
                        label: 'Driver',
                        text: null
                    },
                    {
                        type: 'all',
                        label: 'For all',
                        text: null
                    }
                ],
                notesForAll: null,
                notesForDriver: null,
                reservationStatus: null,
                driverStatus: 'b2UwM5EsIfoOvt9clkoA',
                paymentStatus: null,
                assignedDriver: null,


            },
            request: null,
            ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
            //dispatchRef: this.$firebase.firestore().collection('dispatchers'),
            vehicleRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
            jobTypesRef:  this.$firebase.firestore().collection('job-types'),
            statusesRef: this.$firebase.firestore().collection('statuses'),
            addOnsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
            lastIdRef: this.$firebase.firestore().collection('res-last-id'),
            lastId: null,
            coordinates: {
                lat: 37.09024,
                lng:-95.712891
            },
            markers: [],
            showDialog: false,
            goTo: null,

            // start: "",
            // end: "",
           
        }
    },
    mounted(){
        let th = this;
        this.$store.commit('centerDialog',window.innerWidth)
        this.getAirlines();
       
        
        
    },
    watch: {
        'reservation': {
            handler: function(val, oldVal) {
                
                if(val && oldVal) {
                    console.log('Diff')
                    this.isEditing = true 
                } else {
                    console.log('Else diff')
                }
                
            },
            deep:true
        },
        'newClient.willBePassenger' : {
            handler: function(val,oldVal) {
                console.log(oldVal)
                console.log(val)
                //if(oldVal != null) {
                let willBePassengerExists = false
                let indexToRemove = null
                for (let i = 0; i < this.newClient.passengers.length; i++) {
                    if(this.newClient.passengers[i].willBePassenger == true) {
                        willBePassengerExists = true
                        indexToRemove = i
                    }
                    
                }

                if(!willBePassengerExists && val == 'on') {
                    let passToAdd = {
                        firstName: this.newClient.firstName,
                        lastName: this.newClient.lastName,
                        phone: this.newClient.phone,
                        email: this.newClient.email,
                        additionalPhone: this.newClient.additionalPhone,
                        willBePassenger: true
                    }
                    this.newClient.passengers.push(passToAdd)
                }

                if(val == 'off') {
                    this.newClient.passengers.splice(indexToRemove, 1)
                }

                //this.addPassengerStep = 'passengers-add'
                this.fireNewClientValidation()


                //}
            }
        },
        cardYear () {
            if (this.payment.cardFields.cardMonth < this.minCardMonth) {
                this.payment.cardFields.cardMonth = ''
            }
        },
        computedWaypoints: {
            handler: function (val, oldVal) {
            },
            deep: true
        },
        // 'reservation.jobType': {
        //     handler: function(val,oldVal) {
                
        //         for (let i = 0; i < this.jobTypes.length; i++) {
        //            if(this.jobTypes[i].id === val) {
        //                 this.feeCalculatorData.jobType = this.jobTypes[i]
        //            }
        //         }
        //     }   
        // },
        // 'reservation.vehicleType': {
        //     handler: function(val,oldVal) {
                
        //         for (let i = 0; i < this.vehicleTypes.length; i++) {
        //            if(this.vehicleTypes[i].id === val) {
        //                 this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
        //            }
        //         }
        //     }   
        // },
        'clientHasAccount': {
            handler: function(val,oldVal) {
                console.log(val)

                if(val != null) {
                    this.addPassengerStep = 'client-autocomplete-selector'
                }

                this.validateClientPassengerModal()
                

            }
        },
        'payment.merchantAccount': {
            handler: async function(val,oldVal) {
                
                let selectedMerchant ;
                for(let i = 0 ; i <  this.merchantAccounts.length ; i++) {
                    if(this.merchantAccounts[i].id === val) {
                        selectedMerchant = this.merchantAccounts[i]
                    }
                }

              
                //this.payment.merchantAccount = selectedMerchant.id
                this.payment.gateway = selectedMerchant.gateway
                this.payment.public = selectedMerchant.public
                this.payment.paymentMethod = 'cash'
                this.payment.paymentMethod = 'credit-card'

                if(val != null && this.payment.gateway == 'Stripe' && this.reservation.generatedPrice) {

                    this.initMerchantObject(selectedMerchant.public, 'stripe')
                    // if(this.payment.paymentIntent) {
                    //     await this.updateOrderInformation()
                    // } else {

                    //     await this.sendOrderInformation()
                    // }
                }

                

            }
        },
        'selectedData.datetime': {
            handler: function(val,oldVal) {
                
                let localDateTime = moment.unix(val) ;
                let utcRealDateTime = localDateTime.utcOffset(this.timezoneOffsetDif).format()
                let utcCutOffset;
                if(this.timezoneOffsetDif === 0) {
                    utcCutOffset = utcRealDateTime
                } else {
                    utcCutOffset = utcRealDateTime.split('+')[0] + '+00:00'
                }

                let dbaDateTime = moment.utc(utcCutOffset);
                this.reservation.day = parseInt(dbaDateTime.format('X'));
               
            }

        },
        'reservation.day': {
            handler: function(){
                this.initFlightCheck()
            }
        },
        'reservation.driverStatus': {
            handler: function(val,oldVal) {
                console.log('watch reservation.driverStatus');
                console.log(val)
                if(val === 'YSJGDWBb47imDaIUcmMr'){
                    this.assignationBox = true
                    
                } else {
                    this.assignationBox = false 
                    this.reservation.assignedDriver = null
                }
            
            }
        },
        'reservation.childSeat': {
            handler: function(val,oldVal) {
                this.selectedData.extras.childSeat = val;
            }
        },
        'reservation.accessible': {
            handler: function(val,oldVal) {
                this.selectedData.extras.accessible = val;
            }
        },
        'reservation.meetAndGreet': {
            handler: function(val,oldVal) {
                this.selectedData.extras.meetAndGreet = val;

            }
        },
        'selectedData.jobType': {
            handler: function(val,oldVal) {
                this.filteredVehicleTypes = [];
                this.reservation.vehicleType = null;
                this.selectedData.vehicleType = null;
                for (let i = 0; i < this.serviceTypes.length; i++) {
                   if(this.serviceTypes[i].id === val) {
                        this.feeCalculatorData.jobType = this.serviceTypes[i]
                        this.reservation.jobType = this.serviceTypes[i]
                        //this.serviceTypes[i].vehicleType
                        for (let j = 0; j < this.serviceTypes[i].vehicleType.length; j++) {
                            for (let k = 0; k < this.vehicleTypes.length; k++) {
                                if(this.serviceTypes[i].vehicleType[j] == this.vehicleTypes[k].id ) {
                                    this.filteredVehicleTypes.push(this.vehicleTypes[k]);
                                }
                                
                            }
                            
                        }
                   }
                }
            }   
        },
        'selectedData.companyProfile': {
            handler: function(val,oldVal) {
                for (let i = 0; i < this.companyProfiles.length; i++) {
                   if(this.companyProfiles[i].id === val) {
                        this.reservation.companyProfile = this.companyProfiles[i]
                        for (let k = 0; k < this.reservation.passengers.length; k++) {
                           
                            if(!this.reservation.passengers.guest) {
                                this.reservation.passengers.slice(k,1)
                            }   
                        }
                         
                        this.updateTime()
                        this.flowTimeZone();
                   }
                }
            }   
        },
        'selectedData.vehicleType': {
            handler: function(val,oldVal) {
                console.log('selectedData.vehicleType');
                this.reservation.addOns = []
                for (let i = 0; i < this.vehicleTypes.length; i++) {
                   if(this.vehicleTypes[i].id === val) {
                        this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
                        this.reservation.vehicleType = this.vehicleTypes[i]
                        this.selectedData.passengers = this.vehicleTypes[i].passengers
                        this.selectedData.luggage = this.vehicleTypes[i].luggage
                   }
                }
            }   
        },
        'selectedData.dispatcher': {
            handler: function(val,oldVal) {
                
                for (let i = 0; i < this.dispatchers.length; i++) {
                   if(this.dispatchers[i].uid === val) {
                        this.reservation.dispatcher = this.dispatchers[i]
                       
                   }
                }
            }   
        },
        'selectedData.addOns': {
            handler: function(val,oldVal) {
                console.log(val);
                let addOns = [];
                for (let i = 0; i < val.length; i++) {
                    
                    for (let j = 0; j < this.reservation.vehicleType.rates.addons.length; j++) {
                        if(val[i] == this.reservation.vehicleType.rates.addons[j].id) {
                            addOns.push(this.reservation.vehicleType.rates.addons[j]);
                        }
                        
                    }
                }
                this.reservation.addOns = addOns ;
                this.feeCalculatorData.addOns = addOns ;
                
            }   
        },
        'reservation.vehicleType.rates.addons' : {
            handler: function(val,oldVal) {
                console.log('reservation.vehicleType.rates.addons');
                console.log(val);
                let addOns = [];
                for (let i = 0; i < val.length; i++) {
                    if(parseFloat(val[i].qty) > 0) {
                        addOns.push(val[i])
                    }
                }
                this.reservation.addOns = addOns ;
                this.feeCalculatorData.addOns = addOns ;

            },
            deep: true
        },
        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        }
       
    
    },
    methods: {
        changeMobileTab(id,index) {
            this.$refs.mainTabs.activeTab = id;
            this.activeTabLabel = this.steps[index].label
            this.activeTabIndex = index
            this.showSelector = false

        },

        prevTab() {
            let prevTabId = null ;
            let activeTabIndex = null;

            for (let i = 0; i < this.steps.length; i++) {
                if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                    activeTabIndex = i
                }
                
            }
            console.log(activeTabIndex)
            prevTabId = this.steps[activeTabIndex - 1].id
            this.activeTabLabel = this.steps[activeTabIndex - 1].label
            this.activeTabIndex = activeTabIndex - 1
            this.$refs.mainTabs.activeTab = prevTabId;
        },
        nextTab() {
            let nextTabId = null ;
            let activeTabIndex = null;

            for (let i = 0; i < this.steps.length; i++) {
                if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                    activeTabIndex = i
                }
                
            }
            console.log(activeTabIndex)
            nextTabId = this.steps[activeTabIndex + 1].id
            this.activeTabLabel = this.steps[activeTabIndex + 1].label
            this.activeTabIndex = activeTabIndex + 1
            
            this.$refs.mainTabs.activeTab = nextTabId;

        },
        async saveAndExit() {
            this.isBusy = true;
            await this.validateAllSteps(false)
            this.confirmDialog = false
            this.isBusy = false;
        },
        async onConfirm() {
            this.confirmLeave = true

            if(this.payment.gateway == 'Stripe' && this.payment.paymentIntent)
            await this.cancelOrderInformation()
            this.$router.push({path: this.goTo.path})
        },
        preventNav(event) {
            console.log(event)
            if (!this.isEditing) return
            event.preventDefault()
            event.returnValue = ""
        },
        onCancel() {
            this.confirmDialog = false
        },
        autoFillReservation() {
            var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
            this.reservation.day = parseInt(cd.format('X')) ;


        },
        async submitStripePayment() {
            let th = this
            const elements = this.payment.elements
            console.log(this.$router)
            return new Promise(async (resolve, reject) => {

                let origin = window.location.origin
                let routeList = th.$router.matcher.getRoutes()

                let finalRoute = routeList.filter((route) => {
                    return route.name == 'NewReservation'
                    return route.name == 'Reservations'
                    
                })


                let return_url = `${origin}${finalRoute[0].path}`
                console.log('return_url',return_url)

                // const {error,paymentIntent} = await th.payment.merchantInstance.confirmPayment({
                //     //`Elements` instance that was used to create the Payment Element
                //     elements,
                //     confirmParams: {
                //         return_url: return_url,
                //     },
                //     redirect: 'if_required',
                //     clientSecret: th.payment.clientSecret
                // });

                const { error , confirmationToken } = await th.payment.merchantInstance
                .createConfirmationToken({
                    elements: th.payment.elements,
                })

                
                if( error ) {
                    const messageContainer = document.querySelector('#error-message');
                    messageContainer.textContent = error.message;
                    resolve({error})
                }

                let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
                let data = {
                    amount: amount,
                    currency: 'usd',
                    merchantId: this.payment.merchantAccount,
                    user: this.user.data.uid,
                    confirmationTokenId: confirmationToken.id

                }

                axiosApi.post('stripe/create-confirm-intent',data).then(response =>{
                    
                    resolve(response)
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    
                    resolve(e.response)
                }) 
                

                
            })
        },
        refreshTabHeight() {
            let th = this;
            setTimeout(()=>{

                let mainTabsContainer = th.$refs.mainTabs.$el.children[1]
                console.log('mainTabsContainer',mainTabsContainer)
                let paymentMethodHeight = th.$refs.mainTabs.$el.querySelectorAll('#payment-method')[0].offsetHeight
                console.log('paymentMethodHeight',paymentMethodHeight)
                mainTabsContainer.style.height =  `${paymentMethodHeight}px`
            },2000)
           
        },
        async initMerchantObject(publicKey, provider) {
            let th = this;
            switch (provider) {
                case 'stripe':
                    let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
                    // const options = {
                    //     // clientSecret: clientSecret,
                    //     // Fully customizable with appearance API.
                    //     appearance: {
                    //         theme: 'night',
                    //         labels: 'floating',
                    //         variables: {
                    //             colorPrimary: '#f9ba00'
                    //         }
                    //     },
                    
                    // };

                    const options = {
                        amount:amount,
                        currency: 'usd',
                        mode: 'payment',
                        
                        appearance: {
                            theme: 'night',
                            labels: 'floating',
                            variables: {
                                colorPrimary: '#f9ba00'
                            }
                        },
                        paymentMethodCreation: 'manual',
                        paymentMethodTypes: [
                            'card'
                        ]
                    };
                    this.payment.merchantInstance = await loadStripe(publicKey);
                   

                    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
                    const elements = this.payment.merchantInstance.elements(options);
                    console.log('elements', elements)
                    this.payment.elements = elements


                    // Create and mount the Payment Element
                    const paymentElement = elements.create('payment');
                    // const paymentElement = elements.create('card');
                    paymentElement.mount('#payment-element');

                    paymentElement.on('change', function(event) {
                        console.log(event)
                        th.payment.event = event
                        
                    });

                    paymentElement.on('ready', function(event) {
                        console.log('payment element ready',event)
                        
                        //th.refreshTabHeight()
                        // .$el.offsetHeight , altura con tab incluido
                    });
                    
                    break;
            
                default:
                    break;
            }
        },
        goToMerchant() {
             this.$router.push({name: 'Accounts List'})
        },
        goToClient() {
            // this.$router.push({
            //     //path: `add-ons/${item.id}`
            //     path: `/clients/${this.existingClient.name}/${this.existingClient.id}`,
            // })

            let name = this.existingClient.type === 'corporate' ?  this.existingClient.name : `${this.existingClient.firstName} ${this.existingClient.lastName}`
            this.$router.push({ name: 'Client', params: { name: name , id: this.existingClient.id } })
        },
        async checkClientExistance() {
            console.log('Check client existance method')
            let th = this
            let data = {
                email:this.newClient.email
            }
            return new Promise((resolve, reject) => {
                
                th.$firebase
                .firestore()
                .collection('users')
                .doc(this.user.data.uid)
                .collection('clients')
                .where('email','==', th.newClient.email)
                .get()
                .then(snapshot => {
                    if(snapshot.size > 0) {
                        
                        snapshot.docs.forEach(doc => {
                            let dataToSave = doc.data()
                            dataToSave.id = doc.id
                            th.existingClient = dataToSave
                        })
                    } else {
                        th.existingClient = null
                    }
                    resolve(snapshot.size)
                })


            })
        },
        checkUserExistance(email) {
            return new Promise((resolve, reject) => {
                let data = {
                    email:email
                };
                axiosApi.get(`users/check-existance/${data.email}`).then(response => {
                    resolve(response);
                }).catch(e => {
                    console.log(e);
                    this.isPassenger = null
                    this.isClient = null
                    this.userCheck = null
                    this.checkingExistantClient = false;
                    // this.dialog.userCheck = null;
                    // this.dialog.passenger.checked = false;
                    // this.dialog.checkingUser = false;
                    reject();
                });
            });
        },
        validateOnGoingClient() {
            let email = this.$v.newClient.email.$invalid
            return !email ? true : false 
        },
        async fireClientCheck() {
            this.checkingExistantClient = true;
            if(this.validateOnGoingClient()) {

                await this.checkClientExistance() > 0 ? this.clientExsits = true : this.clientExsits =  false 
                let response = await this.checkUserExistance(this.newClient.email);
                console.log('response' , response)
                if(response) {

                    this.isPassenger = response.data.isPassenger
                    this.isClient = response.data.isClient
                    this.isClient = response.data.isClient
                    this.userCheck = response.data.user;
                    
                    if(this.isPassenger) {
                        this.newClient.firstName = this.userCheck.general.name
                        this.newClient.lastName = this.userCheck.general.lastname
                        this.newClient.phone = this.userCheck.general.phone
                    }

                    if(this.isClient) {
                        this.newClient.name = this.userCheck.general.companyName
                        this.newClient.phone = this.userCheck.general.phone
                    }
                    
                } else {
                    this.isPassenger = null
                    this.userCheck = null
                    
                }
            }
            this.checkingExistantClient = false;
        },
        removeNewClientPassenger(index) {
            this.newClient.passengers.splice(index,1)
        },
        tableScrolledHorizontally() {
            console.log('scrolled')
            let $elem = this.$refs.passengerAddTable.$el;
            //console.log('table.$el.scrollLeft',table.$el.scrollLeft)
            //console.log('table.$el.scrollWidth',table.$el.scrollWidth)
            let lastCol = $elem.querySelectorAll('.md-table-row th:nth-last-child(1)')
          
            var newScrollLeft = $elem.scrollLeft,
                width=$elem.offsetWidth,
                scrollWidth=$elem.scrollWidth;

            var offset = lastCol[0].clientWidth;

            if (scrollWidth- newScrollLeft-width >= offset) {
                this.fixedLastRowActive = true
            } else {
                this.fixedLastRowActive = false
            }


        },
        updateFixedLastCol(ref){
            let table = this.$refs[ref];
            let rows = table.$el.querySelectorAll('.content-row td:nth-last-child(1)')
            console.log(rows)

            this.$nextTick(() => {

                this.fixedLastColRows = []
                for (let i = 0; i < rows.length; i++) {
    
    
                    let dataToSave = {
                        height: rows[i].clientHeight,
                    }
                    this.fixedLastColRows.push(dataToSave)
                    
                }

                
            })
           

        },
        addPassengerToNewClient() {
            if(this.validatePassengerToNewClient()){
                let passengerToAdd = {
                    firstName: this.newPassengerAddition.firstName,
                    lastName: this.newPassengerAddition.lastName,
                    phone: this.newPassengerAddition.phone,
                    email: this.newPassengerAddition.email,
                    additionalPhone: this.newPassengerAddition.additionalPhone,

                }

                this.newClient.passengers.push(passengerToAdd)

                this.newPassengerAddition = {
                    email: null,
                    firstName: null,
                    lastName: null,
                    phone: null,
                    additionalPhone: null,
                }
                this.$v.newPassengerAddition.$reset()
                this.$nextTick(() => {

                    this.updateFixedLastCol('passengerAddTable')
                })

            } else {
                this.$v.newPassengerAddition.$touch()
            }
        },
        validatePassengerToNewClient(){
            let firstName,lastName,passengerEmail,phone ;
            firstName = this.$v.newPassengerAddition.firstName.$invalid;
            lastName = this.$v.newPassengerAddition.lastName.$invalid;
            passengerEmail = this.$v.newPassengerAddition.email.$invalid;
            phone = this.$v.newPassengerAddition.phone.$invalid;
            let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
            return validPassenger
        },
        addNonRegisteredPassenger() {
            let passenger = {
                firstName: this.newClientPassengerDialog.firstName,
                lastName: this.newClientPassengerDialog.lastName,
                phone: this.newClientPassengerDialog.phone,
                email: this.newClientPassengerDialog.email,
                additionalPhone: this.newClientPassengerDialog.additionalPhone,
            }
            if(this.validateNonRegisteredPassenger()) {
                console.log('valid')
                this.reservation.passengers.push(passenger)
            } else {
                console.log('invalid')
                this.$v.newClientPassengerDialog.$touch()
            }

            this.newClientPassengerDialog.active = false
            this.newClientPassengerDialog =  {
                active: false,
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null

            }

        },
        validateNonRegisteredPassenger() {
            let firstName,lastName,passengerEmail,phone ;
            firstName = this.$v.newClientPassengerDialog.firstName.$invalid;
            lastName = this.$v.newClientPassengerDialog.lastName.$invalid;
            passengerEmail = this.$v.newClientPassengerDialog.email.$invalid;
            phone = this.$v.newClientPassengerDialog.phone.$invalid;
            let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
            return validPassenger
        },
        fireNonRegisteredPass() {
            this.newClientPassengerDialog.active = true
        },
        removeSelectedClient() {
               this.reservation.client = null;
            this.reservation.passengers = []
            this.preselected.client = null;
            this.preselected.passengers = null;
            this.addPassengerStep = 'client-has-account'
            
        },
        firePassengerDialog() {
            if(this.reservation.client) {
                this.clientHasAccount = 'on'
                this.preselected.client = this.reservation.client
            } else {
                
            }
            this.$nextTick(() => {
                if(this.reservation.client) {
                    this.addPassengerStep = 'passengers-selector'
                } else {

                }
                this.passengerDialog = true
                //console.log(this.$refs.clientAutocomplete.getElementById(''))

            })
        },
        resetPassengerDialog() {
            this.passengerDialog = false
            this.addPassengerStep = 'passengers-selector'
            this.clientHasAccount = null
            this.preselected.client = null
            this.preselected.passengers = null
            this.newClient = {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                passengers: [],
                willBePassenger: null,
                type:null,
            }

        },
        addPassenger() {
            
            if(this.validateClientPassengerModal()){
                
                
                if(this.clientHasAccount == 'on') {
    
                    this.reservation.client = this.preselected.client
                    let passengersToAdd = []
                    for (let i = 0; i < this.preselected.client.passengers.length; i++) {
                        if(this.preselected.passengers) {
        
                            for (let j = 0; j < this.preselected.passengers.length; j++) {
                                if(this.preselected.client.passengers[i].id == this.preselected.passengers[j].id){
                                    this.preselected.client.passengers[i].alreadySelected = true
                                    passengersToAdd.push(this.preselected.passengers[j])
                                }
                            }
                        }
                        
                    }
                    this.reservation.passengers = [].concat(this.reservation.passengers,passengersToAdd);
        
                   this.resetPassengerDialog()
                    
                } else if(this.clientHasAccount == 'off') {
    
                    let clientToAdd = {
                        email: this.newClient.email,
                        firstName: this.newClient.type == 'individual' ? this.newClient.firstName : null,
                        lastName: this.newClient.type == 'individual' ? this.newClient.lastName : null,
                        name: this.newClient.type == 'corporate' ?  this.newClient.name : null,
                        phone: this.newClient.phone,
                        additionalPhone: this.newClient.additionalPhone,
                        willBePassenger: this.newClient.willBePassenger,
                        type:this.newClient.type,
                        new: true,
                        passengers: this.newClient.passengers
                    }

                    this.reservation.client = clientToAdd
    
                    this.reservation.passengers = this.newClient.passengers
    
                    this.resetPassengerDialog()
    
                    
    
                }
                



            } else {

            }

        },
        validateClientPassengerModal(){
            if(this.clientHasAccount == null) {
                console.log('First step null')
                this.addPassengerStep = 'client-has-account'
                this.clientHasAccountProp.error = 'Select one of these options'
                
            } else if(this.clientHasAccount == 'on') {
                this.clientHasAccountProp.done = true
                this.clientHasAccountProp.editable = false
                if(!this.preselected.client) {
                    this.findClientAccount.error = 'Select client below'
                } else {
                    this.findClientAccount.error = null
                    this.findClientAccount.editable = false
                    this.findClientAccount.done = true

                    return true

                }
            } else if(this.clientHasAccount == 'off') {
                this.clientHasAccountProp.done = true
                this.clientHasAccountProp.editable = false

                if(this.$v.newClient.$invalid) {
                    this.findClientAccount.error = 'Select client below'
                   
                    this.$v.newClient.$touch()
                    if(this.$v.newClient.$anyDirty) {

                        this.fireNewClientValidation()
                    }
                    
                } else {
                    this.findClientAccount.error = null
                    this.findClientAccount.editable = false
                    this.findClientAccount.done = true

                    return true

                }



            }
        },
        validateNewClientInfo(){
            let type,email,name,firstName,lastName,phone,willBePassenger

            type = this.$v.newClient.type.$invalid
            email = this.$v.newClient.email.$invalid
            name = this.$v.newClient.name.$invalid
            firstName = this.$v.newClient.firstName.$invalid
            lastName = this.$v.newClient.lastName.$invalid
            phone = this.$v.newClient.phone.$invalid
            willBePassenger = this.$v.newClient.willBePassenger.$invalid

            if(!email && this.clientExsits) {
                console.log('invalid email 1')
                email = true
            }

            if(this.userCheck) {
                if(!this.isPassenger && this.client.type === 'individual') {
                    console.log('invalid email 2')
                    email = true
                }

                if(!this.isClient && this.client.type === 'corporate') {
                    console.log('invalid email 3')
                    email = true
                }
            }

            return !type && !email && !name && !firstName && !lastName && !phone && !willBePassenger? true : false
        },
        fireNewClientValidation(){
            if(this.validateNewClientInfo()) {
                console.log('Valid New')
                this.addPassengerStep = 'passengers-add'

                this.$nextTick(() => {
                    this.updateFixedLastCol('passengerAddTable')
                })

                this.$refs.passengerAddTable.$el.addEventListener('scroll', this.tableScrolledHorizontally)
                
                this.findClientAccount.error = null
                this.findClientAccount.editable = false
                this.findClientAccount.done = true
            } else {
                this.$v.newClient.$touch()
                this.findClientAccount.error = 'Complete required info for new client'
                this.findClientAccount.editable = true
                this.findClientAccount.done = false

            }
        },
        clientChanged(term) {
            if(!term) {
                this.reservation.client = null
            }
        },
        passengersSelected(items) {
            this.preselected.passengers = items
        },
        getAlternateLabel (count) {
            let plural = ''

            if (count > 1) {
            plural = 's'
            }

            return `${count} passenger${plural} selected`
        },
        positionMenu() {
            let list = document.querySelectorAll('.md-menu-content')
            
            
            
            if(
                this.$refs.airlineAutocomplete && 
                list.length > 0 && 
                list[0].querySelectorAll('.airline-code').length > 0){
                console.log('RRRee position menu')
                let input = this.$refs.airlineAutocomplete
                
                
    
                // Get current location's distance from the top of the page
                var position = window.pageYOffset;
                // Get an element's distance from the top of the page
                var getElemDistance = function ( elem ) {
                    var location = 0;
                    if (elem.offsetParent) {
                        do {
                            location += elem.offsetTop;
                            elem = elem.offsetParent;
                        } while (elem);
                    }
                    return location >= 0 ? location : 0;
                };
                //var elem = document.querySelector('#some-element');
                var location = getElemDistance( input.$el );
                if(list[0]) {
                    setTimeout(function(){

                        list[0].style.top = ( input.$el.offsetHeight + location ) + 'px'
                    },100)

                }
            }
        },
        operatorSelectorOpened() {
            //.addEventListener
            this.positionMenu()
        },
        operatorSelectorClosed() {
        },
        createBT() {
             const client = require('braintree-web/client');
            client.create({
                    authorization: this.braintreeToken
                }, (clientErr, clientInstance) => {
                if (clientErr) {
                    console.log(this.errorMessage);
                    
                    return;
                } else {
                    this.clientInstance = clientInstance
                    console.log(this.clientInstance);
                    this.createHF();
                    // if (this.enableDataCollector) {
                    //     this.dataCollectorCreate();
                    // }
                }
            });
        },
        createHF() {
            let th = this;
            console.log('Create HF');
            const hostedFields = require('braintree-web/hosted-fields');
            // let cvv = this.$refs.cardCvv.$el
            // let cardName = this.$refs.cardName.$el
            // let cardNumber = this.$refs.cardNumber.$el
            // let cardMonth = this.$refs.cardMonth.$el.childNodes[0]
            // let cardYear = this.$refs.cardYear.$el.childNodes[0]
            // cardholder
            // number
            // postal
            // cvv
            // expiration-date
           
            hostedFields.create({
                client: this.clientInstance,
                // styles: {
                //     'input': {
                //     'font-size': '18px'
                //     },
                //     'input.invalid': {
                //     'color': 'red'
                //     },
                //     'input.valid': {
                //     'color': 'green'
                //     }
                // },
                fields: {
                    number: {
                        //container: cardNumber,
                        container: '#number',
                        
                    },
                    cvv: {
                        //container: cvv,
                        container: '#cvv',
                        
                    },
                    postalCode: {
                        container:'#postal'
                    },
                    cardholderName: {
                        container: '#cardholder'
                    },
                    // expirationMonth: {
                    //     container: cardMonth,
                    // },
                    // expirationYear: {
                    //     container: cardYear,
                    // },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: '10/2019',
                    },
                },
            }, (hostedFieldsErr, hostedFieldsInstance) => {
                if (hostedFieldsErr) {
                    // Handle error in Hosted Fields creation
                    //this.errorMessage = 'There was an error setting up the hosted fields! Message: ' + hostedFieldsErr.message;
                    console.log(hostedFieldsErr.message);
                    //this.$emit('bthferror', this.errorMessage);
                    return;
                } else {
                    //this.$emit('bthfready');
                    this.hostedFieldsInstance = hostedFieldsInstance;
                    console.log(hostedFieldsInstance);
                    hostedFieldsInstance.on('focus', function (event) {
                        console.log(event);
                        console.log(event.emittedBy, 'has been focused');
                    });

                    hostedFieldsInstance.on('cardTypeChange', function (event) {
                        
                        console.log('cardTypeChange');

                        switch(event.emittedBy) {
                            case 'number':
                                //th.changeNumber(event.fields.number)
                            break;
                        }
                        
                       
                    });
                }
            });
        },
        tokenizeHF () {
            console.log(this.hostedFieldsInstance);
            
            let additionalFields = {
                cardholderName: '',
                billingAddress: {
                    postalCode: '',
                },
            };
            
            additionalFields.cardholderName = this.payment.cardFields.cardName
           
            additionalFields.billingAddress.postalCode = this.payment.cardFields.billZip;
            

            console.log(additionalFields);

            this.hostedFieldsInstance.tokenize(additionalFields, (tokenizeErr, payload) => {
                if (tokenizeErr) {
                    console.log(tokenizeErr.message);
                    // this.errorMessage = 'There was an error tokenizing! Message: ' + tokenizeErr.message;
                    // this.$emit('bthferror', this.errorMessage);
                    return;
                }

                this.tokenizePayload = payload;
                console.log(this.tokenizePayload);
                this.createTransaction()
                //this.$emit('bthfpayload', payload);
                //this.teardownHF();
            });
            
        },
        async createTransaction(){
           let th = this;
            return new Promise((resolve, reject) => {
                let data = {};
                data.nonce = th.tokenizePayload.nonce;
                data.amount = th.roundNumber(th.reservation.generatedPrice);
                axiosApi.post('create-transaction',data).then(response =>{
                    console.log(response);
                    resolve()
                   
                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })
        },
        async getMerchantAccounts() {
            var params = {
                user: this.user.data.uid
            }
            let th = this;
            return new Promise((resolve, reject) => {
                axiosApi.get('active-merchant-accounts/' + th.user.data.uid).then(response =>{
                    th.merchantAccounts = response.data.accounts ;
                    for (let i = 0; i <  th.merchantAccounts.length; i++) {
                        if(th.merchantAccounts[i].default) {
                            th.payment.merchantAccount = th.merchantAccounts[i].id
                            th.payment.gateway = th.merchantAccounts[i].gateway
                        }


                        
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
        submitTransaction() {
            console.log('Submit transaction');
           this.tokenizeHF()
            //this.$refs.hosted.tokenizeHF()
            
        },
        onBraintreeSuccess(payload){
            //let nonce = payload.nonce;
            console.log(payload)
        },
        onBraintreeError(error){
            //let message = error.message;
            console.log(error);
        },
        uncheckQuotation(value){
           
            if(value) {
                this.reservation.confirmationEmails.quotation.passenger.send = false
            }
           
        },
        quotationChanged() {
            console.log('quotationChanged');
            if(this.reservation.confirmationEmails.quotation.passenger.send){

                this.reservation.confirmationEmails.confirmation = {
                    passenger: {
                        send: false,
                    },
                    owner: {
                        send: false
                    },
                    dispatcher: {
                        send: false
                    },
                    
                }

                this.reservation.confirmationEmails.receipt = {
                    passenger: {
                        send: false,
                    },
                        
                }
                
                this.reservation.confirmationEmails.driverAssignation = {
                    passenger: {
                        send: false,
                    },
                    owner: {
                        send: false
                    },
                    driver: {
                        send: false
                    },
                    
                }
            }
        },
        updateTime() {
            var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
            this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
           
        },
        flowTimeZone() {
            var tz = moment.tz.guess();
            var dbaTZoffset = moment.tz(this.reservation.companyProfile.timeZone).utcOffset()

            var creatorTZoffset = moment.tz(tz).utcOffset()


            if(creatorTZoffset > dbaTZoffset) {
                // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
                // Moscu 180 (dba) // nuevadelih (creator) 300

                this.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;

                // -180 -  -240 = 60
                // 300 - 180 = 120
            } else if(dbaTZoffset > creatorTZoffset) {

                // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
                // Moscu 180 (creator) // nuevadelih (dba) 300
                this.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
                // -240 - -180 = 
                // 180 - 300 = -120
            } else {
                this.timezoneOffsetDif = 0;
            }




        },
        addPromoCode(){
            if(this.feeCalculatorData.promoCode != '' && this.feeCalculatorData.promoCode && !this.reservation.promoCodes.includes(this.feeCalculatorData.promoCode)) {
                this.reservation.promoCodes.push(this.feeCalculatorData.promoCode)
                this.feeCalculatorData.promoCode = null;
            }
            
        },
        roundNumber(number) {
            if(number) {
                
                number = number.toString(); //If it's not already a String
                if(number.indexOf(".") != -1) {
                    number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
                } 
            }
            
            return number
        },
        async printPriceTable(data){
            console.log('Price table data',data)
            this.rateData = data;
            this.reservation.generatedPrice =  this.roundNumber(data.price)
            this.reservation.rateData = data;
            this.payment.cash.paidAmount = this.roundNumber(data.price)
            this.cardToPayAmount = this.roundNumber(data.price)
            if(this.payment.gateway == 'Stripe') {
                this.initMerchantObject(this.payment.public, 'stripe')
                // if(this.payment.paymentIntent) {
                //     await this.updateOrderInformation()
                // } else {

                //     await this.sendOrderInformation()
                // }

            }

            
        },
        mainTabChanged(tabId) {
            let index
            for (let i = 0; i < this.steps.length; i++) {
                
                if(tabId == this.steps[i].id) {

                    this.activeTabLabel = this.steps[i].label
                    index = i
                }
            }
            
            this.activeTabIndex = index
            
        },
        jobTypeChanged(event){
            console.log(event);
        },
        async getDrivers(){
            let th = this ;
            let data = {  
                    ownerEmail: this.user.data.email,
                    roleToGet: 'driver' 
            }       

            return new Promise((resolve, reject) => {
                
                axiosApi.get('drivers', { params: data }).then(response =>{
                        //th.drivers = response.data.users ;
                        console.log(response)
                        resolve()
                    }).catch(e => {
                        console.log(e);
                        reject()
                    })
            })
            
        },
        async getDispatchers(){
            let th = this ;

            let data = {  
                    ownerEmail: this.$store.getters.user.data.email,
                    roleToGet: 'dispatcher'
            }

            

            return new Promise((resolve, reject) => {
                
                this.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                    //Send token to your backend via HTTPS
                    //...
                    axiosApi.get('dispatchers', { params: data }).then(response =>{
                        //console.log(response);
                        th.dispatchers = response.data.users ;
                        resolve()
                    }).catch(e => {
                        console.log(e);
                        reject()
                    })
                }).catch(function(error) {
                    //Handle error
                    reject()
                });
            })


        
        },
        tabChanged(tab) {
            //console.log(tab);
        },
        getJobTypeName(jobTypeId) {
            for (let i = 0; i < this.jobTypes.length; i++) {
                if(this.jobTypes[i].id == jobTypeId ) {
                    return this.jobTypes[i].name ;
                }
                
            }

            // this.jobTypesRef.doc(jobTypeId).get()
            //     .then(function(doc) {
            //         if (doc.exists) {
            //             console.log(doc.data().name);
            //             let a = doc.data().name;
            //             return a;
            //         } else {
            //             // doc.data() will be undefined in this case
            //             console.log("No such document!");
            //             return false ;
            //         }
            //     }).catch(function(error) {
                    
            //         console.log("Error getting document:", error);
            //         return false ;
            //     });
        },
        resetFlightPack() {
            // this.reservation.flightAirline = null
            // this.reservation.flightIcao = null
            // this.reservation.flightIata = null
            this.reservation.flightPack =  {
                                icao: null,
                                iata: null,
                                schedule: null,
                                info: null,
                                number:null,
                                noMatch: false,
                                originAirport: null,
                                destinationAirport: null,

                            }
        },
        async initFlightCheck() {

            this.resetFlightPack()
           
             if(
                ( this.reservation.day 
                 && this.reservation.flightNumber 
                 && this.reservation.flightNumber.length >= 2 
                ) 
                && 
                (
                 this.reservation.flightIcao 
                 ||
                 this.reservation.flightIata 
                ) 
             ){
                this.loadingFlight = true ;
                this.reservation.flightNumber= this.reservation.flightNumber.replace(/\s/g,'')
                console.log('start')
                console.log(this.reservation.flightNumber)
                const schedules = await this.getFlightSchedules()
                console.log(schedules)
                console.log(this.reservation.flightNumber)
                console.log('end')
                // // if(this.possibleFlights.length > 0){
                // //      await this.getAirlineFlightInfo()
                // // }

                
             }     
            
            this.loadingFlight = false ;
        },
        async getFlightInfo(){
            let th = this;
            return new Promise((resolve, reject) => {
                let data = {
                    flightNumber: th.reservation.flightCode + th.reservation.flightNumber,
                    departuretime: th.possibleFlights[0].departuretime
                }

                axiosApi.get('get-flight-info/' , { params: data }

                ).then(response =>{
                   
                    let callback = JSON.parse(response.data.body)
                    console.log(callback)
                    if(callback.FlightInfoExResult) {
                        console.log('actual departure time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualdeparturetime).utc().format('LLLL'))
                        console.log('estimated arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].estimatedarrivaltime).utc().format('LLLL'))
                        console.log('actual arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualarrivaltime).utc().format('LLLL'))
                        // origin
                        // originCity
                        // originName

                        // destination
                        // destinationCity
                        // destinationName          
                    }
                    resolve()
                }).catch(e => {
                    // this.reservation.flightInfo = null;
                    // this.loadingFlight = false ;
                    console.log(e);
                    reject()
                    // this.serverError = false ;
                })  

                
              
            })  
        },
        async getFlightSchedules() {
          

            let th = this;
            return new Promise((resolve, reject) => {
                if(this.reservation.flightNumber && this.reservation.day) {

                    

                    this.possibleFlights = []
                    
                    let qty = 100;
                    //let flight = this.reservation.flightCode + this.reservation.flightNumber
                    let code = this.reservation.flightIata ?  this.reservation.flightIata  : this.reservation.flightIcao ? this.reservation.flightIcao : null;
                    //let code = this.reservation.flightIcao ?  this.reservation.flightIcao  : this.reservation.flightIata ? this.reservation.flightIata : null;
                    let flight = code + this.reservation.flightNumber
                    
                    let flightAirline = code
                    let flighNumber = this.reservation.flightNumber
                    //let startDate = moment.unix(this.reservation.day).hour(0).minute(0).unix(); 
                    //let endDate = moment.unix(this.reservation.day).hour(23).minute(59).unix() ;



                    // Set departure time range to return
                    let format = 'YYYY-MM-DD HH:mm:ss'
                    let startDate = null
                    let endDate = null

                    // The starting date range for flight results, comparing against flights' 
                    // scheduled_out field (or scheduled_off if scheduled_out is missing)
                    // Comparing against DEPARTURE 

                    var jobTypeName = this.getJobTypeName(this.reservation.jobType.jobType)


                    

                    if(jobTypeName == 'From airport') {
                        console.log('From airport')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix()
                        endDate = moment.unix(this.reservation.day).unix()
                        
                    } else if(jobTypeName == 'To Airport') {
                        console.log('To airport')
                        startDate = moment.unix(this.reservation.day).subtract(2,'h').unix()
                        endDate = moment.unix(this.reservation.day).add(12,'h').unix()
                    } else {
                        console.log('Not service type selected')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix()
                        endDate = moment.unix(this.reservation.day).unix()
                    }  
                    




                    // if now - this.reservation.day diff is more than 
                    let now = moment()
                    let timeToCheck = moment.unix(startDate)
                    let hoursDiff = timeToCheck.diff(now, 'hours');

                    console.log('hoursDiff',hoursDiff)
                    
                    if(hoursDiff > 36) {
                        this.reservation.flightPack =  {
                            icao: null,
                            iata: null,
                            schedule: null,
                            info: null,
                            number:null,
                            originAirport: null,
                            destinationAirport: null

                        }

                        //this.reservation.flightPack.noMatch = true
                        this.reservation.flightPack.dayLimitExceeded = true
                        resolve()
                    } else {
                        let parsedToUtcDatetimeStart = moment.unix(startDate).utc().format()
                        let parsedToUtcDatetimeEnd = moment.unix(endDate).utc().format()
                        console.log(parsedToUtcDatetimeStart);
                        console.log(parsedToUtcDatetimeEnd);
                        this.cancel();
    
                        let axiosSource = axios.CancelToken.source();
                        //let axiosSource = axiosApi.CancelToken.source();
                        this.request = { cancel: axiosSource.cancel };
                        let icaoShield = this.reservation.flightIcao != '' ? this.reservation.flightIcao : null
                        let iataShield = this.reservation.flightIata != '' ? this.reservation.flightIata : null
    
                        
                        let data = {
                            jobType: this.reservation.jobType.jobType,
                            

                        }

                        axiosApi.get('flights-schedules/' 
                                + parsedToUtcDatetimeStart + '/'
                                + parsedToUtcDatetimeEnd + '/'
                                + flightAirline + '/'
                                + icaoShield + '/'
                                + iataShield + '/'
                                + flighNumber + '/'
                                + qty + '/'
                                + this.reservation.day + '/'
                                + this.reservation.pu.formatted_address + '/'
                                + this.reservation.do.formatted_address,

                                { cancelToken: axiosSource.token , params: data }
                        ).then(response =>{
    
                            console.log(response)
                            
                            var payload = response;
                            
                            if(payload.data.flight) {
                                this.reservation.flightPack.noMatch = false
                                this.reservation.flightPack = payload.data.flight
                                let originAirport = this.getAirportInfo(this.reservation.flightPack.schedule.origin.code_icao)
                                let destinationAirport = this.getAirportInfo(this.reservation.flightPack.schedule.destination.code_icao)
                                            
                                this.reservation.flightPack.originAirport = originAirport;
                                this.reservation.flightPack.destinationAirport = destinationAirport;
                            } else {
                                this.reservation.flightPack =  {
                                    icao: null,
                                    iata: null,
                                    schedule: null,
                                    info: null,
                                    number:null,
                                    originAirport: null,
                                    destinationAirport: null
    
                                }
    
                                this.reservation.flightPack.noMatch = true
                                
                            }
                            
    
    
                            
                            resolve()
                            
                        }).catch(e => {
                            this.reservation.flightInfo = null;
                            //this.loadingFlight = false ;
                            
                            this.serverError = false ;
                            console.log(e)
                            //reject({message: 'cancelled request'})
                        }) 
                        
                    }

                    
                       
                } else {
                    this.serverError = false ;
                    this.loadingFlight = false ;
                     resolve()
                }
            })
        },
        cancel() {
            if (this.request) this.request.cancel('New request called');

        },
        cancelPaymentIntentRequest() {
            if (this.paymentIntentRequest) this.paymentIntentRequest.cancel('New payment intent request called');
        },
        setDispatcher(item) {
            //this.reservation.dispatcher = item;
            this.reservation.dispatcher = {
                displayName: item.displayName,
                email: item.email,
                customClaims: item.customClaims
            }
        },
        setAirline(item){
            console.log(item);

            
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;

            
            this.reservation.flightAirline = item.name ;
            this.reservation.flightIcao = item.icao;
            this.reservation.flightIata = item.iata;
            this.$nextTick(() => {
                 this.$refs.airlineAutocomplete.showMenu = false
            })
           
           
            this.initFlightCheck();
        },
        addGuestPassenger() {
            if(this.validateGuestPassenger()) {
                console.log('Valid')
                this.reservation.passengers.push(this.passenger)
                this.passenger = {
                    guest: true,
                    firstName: '',
                    lastName:'',
                    email:'',
                    phone:'',
                    additionalPhone: '',
                    qty: null,
                    luggage:null
                }
                this.$v.passenger.$reset()
                
            } else {
                console.log('Invalid')
                this.$v.passenger.$touch()
            }
        },
        removeAddedPassenger(item,index) {
            

            

            if(this.preselected.client) {
                for (let j = 0; j < this.preselected.client.passengers.length; j++) {
                    if(this.preselected.client.passengers[j].id == item.id) {
                        console.log('inside')
                        delete this.preselected.client.passengers[j].alreadySelected
                    }
                    
                }
            }

            if(this.reservation.passengers) {
                for (let i = 0; i < this.reservation.passengers.length; i++) {
                    if(this.reservation.passengers[i].id == item.id) {
                        console.log('inside')
                        delete this.reservation.passengers[i].alreadySelected
                    }
                    
                }
            }

            this.reservation.passengers.splice(index,1)
        },
        validateGuestPassenger() {
            let firstName,lastName,passengerEmail,phone ;
            firstName = this.$v.passenger.firstName.$invalid;
            lastName = this.$v.passenger.lastName.$invalid;
            passengerEmail = this.$v.passenger.email.$invalid;
            phone = this.$v.passenger.phone.$invalid;
            let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
            return validPassenger
        },
        setPassenger(item) {
            console.log(item)
            this.reservation.passengers.push(item)
            this.passengerInput = ''
            
        },
        setClient(item) {
            //console.log(item)
            this.preselected.client = item
            this.addPassengerStep = 'passengers-selector'
            this.validateClientPassengerModal()

            
        },
        getAirportInfo(icao) {
           let payload; 
           for (const airport in this.airportsList) {
               if (Object.hasOwnProperty.call(this.airportsList, airport)) {
                   const element = this.airportsList[airport];
                   
                   if(element.icao == icao) {
                       payload = element;
                   }
                   
               }
           }
           
           return payload;
    
        },
        async focusedClients(ev) {
            //ev.target.value = ""
            //this.filteredClients = await this.getFilteredClients()
        },
        getFilteredClients(searchTerm){

            console.log('getFilteredClients')
            return new Promise((resolve, reject) => {
                // window.setTimeout(() => {
                //     if (!searchTerm) {
                //         let aux = this.clients.slice(0, 10);
                //         resolve(aux);
                //     } else {
                //         const term = searchTerm.toLowerCase()
                //         console.log(term)
                        
                //         resolve(this.clients.filter(function(v, i) {
                //             console.log(v,i)
                //             let willReturn = false;
                //             if(v.name && v.name.toLowerCase().includes(term)){
                //                 willReturn = true
                //             }

                //             if(v.phone && v.phone.toLowerCase() == term){
                //                 willReturn = true
                //             }

                //             if(v.email && v.email.toLowerCase() == term) {
                //                 willReturn = true
                //             }

                //             return willReturn

                //         }).slice(0, 100))
                            
                //     }
                // }, 0)
            })


           
        },
        async focusedPassengers(ev) {
            ev.target.value = ""
            this.filteredPassengers = await this.getFilteredPassengers()
        },
        focusedAirlines(ev){
            //console.log('Focused airlines')
            //this.resetFlightPack()
            //console.log(this.$refs.airlineAutocomplete);
            console.log(ev)
            
            ev.target.value = ""
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;
            this.getAirlines()

            this.operatorSelectorOpened()
        },
        async fireClientFilter() {
            console.log('fireClientFilter')
            //this.filteredClients = await this.getFilteredClients()
        },
        async firePassengerFilter() {
            this.filteredPassengers = await this.getFilteredPassengers()
        },
        getFilteredPassengers(searchTerm){


            return new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    if (!searchTerm) {
                        let aux = this.passengers.slice(0, 10);
                        resolve(aux);
                    } else {
                        const term = searchTerm.toLowerCase()
                        
                        resolve(this.passengers.filter(function(v, i) {
                            
                            
                            return (
                                        v.firstName.toLowerCase().includes(term) || 
                                        v.phone.toLowerCase() == term.toLowerCase() || 
                                        v.email.toLowerCase() == term.toLowerCase() || 
                                        v.lastName.toLowerCase() == term.toLowerCase() ||
                                        v.clientName.toLowerCase() == term.toLowerCase() ||
                                        v.clientPhone.toLowerCase() == term.toLowerCase()
                                    );
                        }).slice(0, 100))
                            
                    }
                }, 0)
            })


            
        },
        getAirlines(searchTerm) {
            
            this.resetFlightPack()
            
            
            this.airlines = new Promise(resolve => {
                window.setTimeout(() => {
                    if (!searchTerm) {
                        let aux = this.airlinesList.slice(0, 10);
                        resolve(aux);
                        //resolve(this.airlinesList)
                    } else {
                        const term = searchTerm.toLowerCase()
                        
                        //resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                        resolve(this.airlinesList.filter(function(v, i) {
                            
                            
                            return (v.name.toLowerCase().includes(term) || v.icao.toLowerCase() == term.toLowerCase() || v.iata.toLowerCase() == term.toLowerCase());
                        }).slice(0, 100))
                            
                    }
                }, 0)
            })
           
        },
        changeName (e) {
            this.payment.cardFields.cardName = e.target.value
            this.$emit('input-card-name', this.payment.cardFields.cardName)
        },
        changeNumber (e) {
            //console.log(e);
            // let element = e.container.querySelectorAll('#braintree-hosted-field-number');

            // console.log(element) 
            this.payment.cardFields.cardNumber = e.target.value
            const value = this.payment.cardFields.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            } else if (/^62[0-9]\d*/.test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{6})/, '$1 ').replace(/(\d{6}) (\d{7})/, '$1 $2 ').replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ').replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4')
                this.cardNumberMaxLength = 21
            } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line
            if (e.inputType == 'deleteContentBackward') {
                const lastChar = this.payment.cardFields.cardNumber.substring(this.payment.cardFields.cardNumber.length, this.payment.cardFields.cardNumber.length - 1)
                // eslint-disable-next-line
                if (lastChar == ' ') { this.payment.cardFields.cardNumber = this.payment.cardFields.cardNumber.substring(0, this.payment.cardFields.cardNumber.length - 1) }
            }
            this.$emit('input-card-number', this.payment.cardFields.cardNumber)
        },
        changeMonth () {
            this.$emit('input-card-month', this.payment.cardFields.cardMonth)
        },
        changeYear () {
            this.$emit('input-card-year', this.payment.cardFields.cardYear)
        },
        changeCvv (e) {
            this.payment.cardFields.cardCvv = e.target.value
            this.$emit('input-card-cvv', this.payment.cardFields.cardCvv)
        },
        generateMonthValue (n) {
            return n < 10 ? `0${n}` : n
        },
        toggleMask () {
            this.isCardNumberMasked = !this.isCardNumberMasked
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            } else {
                this.unMaskCardNumber()
            }
        },
        maskCardNumber () {
            this.payment.cardFields.cardNumberNotMask = this.payment.cardFields.cardNumber
            this.mainCardNumber = this.payment.cardFields.cardNumber
            const arr = this.payment.cardFields.cardNumber.split('')
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== '') {
                arr[index] = '*'
                }
            })
            this.payment.cardFields.cardNumber = arr.join('')
        },
        unMaskCardNumber () {
            this.payment.cardFields.cardNumber = this.mainCardNumber
        },
        ////
        stepChanged(id)
        {
            this.step = id;
        },
        myEventHandler(e) {
            // your code for handling resize...
            this.$store.commit('centerDialog',window.innerWidth)   
        },
        nextOrSave(){
            console.log(this.step);
            console.log(this.$v);

            

            // General
            
            this.$v.reservation.jobType.$touch();
            this.$v.reservation.vehicleType.$touch();
            this.$v.reservation.passenger.qty.$touch();
            this.$v.reservation.passenger.luggage.$touch();
            this.$v.reservation.dispatcher.$touch();


            // Date, time & location
            this.$v.reservation.day.$touch();
            this.$v.reservation.pu.$touch();
            this.$v.reservation.do.$touch();
            this.$v.reservation.flightNumber.$touch();
            this.$v.reservation.flightAirline.$touch();


            // Passenger and payment
            this.$v.reservation.passenger.firstName.$touch();
            this.$v.reservation.passenger.lastName.$touch();
            this.$v.reservation.passenger.email.$touch();
            this.$v.reservation.passenger.phone.$touch();

            this.$v.payment.cardFields.cardNumber.$touch();
            this.$v.payment.cardFields.cardName.$touch();
            this.$v.payment.cardFields.cardMonth.$touch();
            this.$v.payment.cardFields.cardYear.$touch();
            this.$v.payment.cardFields.cardCvv.$touch();
            this.$v.payment.cardFields.billZip.$touch();
            this.$v.payment.cardFields.billingAddress.$touch();

            this.$v.payment.cash.paidAmount.$touch();

            this.$v.payment.check.companyName.$touch();
            this.$v.payment.check.address.$touch();
            this.$v.payment.check.routing.$touch();
            this.$v.payment.check.account.$touch();

            this.$v.payment.invoice.companyName.$touch();
            this.$v.payment.invoice.address.$touch();
            this.$v.payment.invoice.ein.$touch();
            this.$v.payment.invoice.email.$touch();

           
        },
        async validateAllSteps(collect){
            this.payment.collect = collect;
            this.busyMessage = 'Validating steps';
            this.isBusy = true;

            let validSteps = [];
            let firstCorrectableStep = undefined;
            let steps = ['date-time-location','general','dispatchment-info','addons','rates-and-taxes','passenger','payment-method','emails'] ;
            for (let i = 0; i < steps.length; i++) {
                if(await this.validateStep(steps[i])) {
                    validSteps.push(true)
                } else {
                    validSteps.push(false)
                    if(firstCorrectableStep === undefined) {
                        firstCorrectableStep = steps[i] ;
                    }
                }
                // console.log('this.validateStep(steps[i])',await this.validateStep(steps[i]))
                // validSteps.push(false)
                      
            }

            console.log('validSteps',validSteps)
            if(validSteps.includes(false)) {
                console.log('At least one false step')
                console.log('firstCorrectableStep',firstCorrectableStep);
                this.$refs.mainTabs.activeTab = firstCorrectableStep
                //this.mainActiveTab = 'general' ;
                this.$v.reservation.$touch();
                this.$v.payment.$touch();
                this.isBusy = false;
            } else {
                console.log('All steps are valid')
                
                // TODO: All steps valid, fire add () method
                let addedReservation =  await this.add() 
                this.isBusy = false;
                // BUG: check what happens when save without collect addedReservation.data undefined
                console.log('addedReservation',addedReservation)
                if(addedReservation.data) {
                    this.confirmLeave = true
                    switch(this.payment.gateway) {
                        case 'Paypal PayFlow Pro':
                        
                        this.$router.push({path: `/app/reservations/${addedReservation.data.newReadableId}/${addedReservation.data.newId}`})
                        break;
    
                        case 'Stripe':
                        this.$router.push({path: `/app/reservations/${addedReservation.data.newReadableId}/${addedReservation.data.newId}`})
                        break;
                    }
                } else if(addedReservation.error) {

                    // setTimeout(()=> {
    
                    //     if(this.tabs.paymentMethod.errors > 0) {
                    //         this.$refs.mainTabs.activeTab = 'payment-method';
                    //         this.$refs.tabs.activeTab = 'credit-card';
        
                    //     }
                    // },2000)
                }
                

            }

            
        },
        async sendOrderInformation() {
            console.log('this.payment.paymentIntent',this.payment.paymentIntent)
            let th = this



            let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
            let data = {
                amount: amount,
                currency: 'usd',
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid

            }

            
            this.cancelPaymentIntentRequest();
            let axiosSource = axios.CancelToken.source();

            this.paymentIntentRequest = { cancel: axiosSource.cancel };


            console.log(data)
            return new Promise((resolve, reject) => {
                axiosApi.post('stripe/create-payment-intent',data, { cancelToken: axiosSource.token }).then(response =>{
                    console.log(response);
                    if(response.data.client_secret) {
                        th.initMerchantObject(response.data.public_key, 'stripe')
                        th.payment.clientSecret = response.data.client_secret
                    }

                    if(response.data.id) {
                        th.payment.paymentIntent = response.data.id
                    }
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })

        },
        async updateOrderInformation() {
            let th = this

            let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
            let data = {
                amount: amount,
                currency: 'usd',
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid

            }




            console.log(data)

            return new Promise((resolve, reject) => {
                axiosApi.post(`stripe/update-payment-intent/${th.payment.paymentIntent}`,data).then(response =>{
                    console.log(response);
                    if(response.data.client_secret) {
                        th.initMerchantObject(response.data.public_key, 'stripe')
                        th.payment.clientSecret = response.data.client_secret
                    }

                    if(response.data.id) {
                        th.payment.paymentIntent = response.data.id
                    }
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })
        },
        async cancelOrderInformation() {
            let th = this

            let data = {
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid
            }

            console.log(data)

            return new Promise((resolve, reject) => {
                axiosApi.post(`stripe/cancel-payment-intent/${th.payment.paymentIntent}`,data).then(response =>{
                    console.log(response);
                    
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })

        },
        async checkout(){
            switch(this.payment.paymentMethod) {
                case 'credit-card':
                    this.checkCard();
                break;
                default:
                     this.checkCard();
                break

            }
        },
        async getBraintreeToken() {
            let th = this;
            return new Promise((resolve, reject) => {
                axiosApi.get('generate-token').then(response =>{
                    console.log(response);
                    th.braintreeToken = response.data.token.clientToken;
                    resolve()
                   
                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })
        },
        async checkCard() {
            let th = this;
            // return new Promise((resolve, reject) => {
            //     let data = {};
            //     data.account = th.merchantAccounts[0];
            //     data.value = th.roundNumber(th.reservation.generatedPrice);
            //     axios.create({
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + this.user.data.refreshToken
            //     }
                
            //     //}).post('https://us-central1-uplimoapp.cloudfunctions.net/api/pay-reservation',data).then(response =>{
            //     }).post('http://localhost:5001/uplimoapp/us-central1/api/pay-reservation',data).then(response =>{
            //         console.log(response);
                    
            //         resolve()
                   
            //     }).catch(e => {
                
                   
            //         console.log(e);
            //         reject()
            //     }) 
            // })
            
        },
        sendMail(resId){
            let reservation = this.reservation
            let th = this;
            let data = [];

            // Confirmation mails

            if(this.reservation.confirmationEmails.confirmation.passenger.send) {
                data.push({
                    mailType: 'confirmation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.email,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }

            if(this.reservation.confirmationEmails.confirmation.owner.send) {
                data.push({
                    mailType: 'confirmation',
                    recipientType: 'owner',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.companyProfile.mailList,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }


            if(this.reservation.confirmationEmails.confirmation.dispatcher.send) {
                data.push({
                    mailType: 'confirmation',
                    recipientType: 'creator',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: th.user.data.email,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }


            // Receipt mails

            if(this.reservation.confirmationEmails.receipt.passenger.send) {
                data.push({
                    mailType: 'receipt',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.email,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }

            // Driver assignation mails

            if(this.reservation.confirmationEmails.driverAssignation.passenger.send) {
                data.push({
                    mailType: 'driver assignation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.email,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }

            if(this.reservation.confirmationEmails.driverAssignation.owner.send) {
                data.push({
                    mailType: 'driver assignation',
                    recipientType: 'owner',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.companyProfile.mailList,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }


            if(this.reservation.confirmationEmails.driverAssignation.driver.send) {
                data.push({
                    mailType: 'driver assignation',
                    recipientType: 'driver',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.assignedDriver,
                    message: {
                        subject: 'Reservation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }


            // Quota mails

            if(this.reservation.confirmationEmails.quotation.passenger.send) {
                data.push({
                    mailType: 'quotation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.email,
                    message: {
                        subject: 'Quotation #' + reservation.readableId + ' created',
                            html: 'PU. ' + reservation.pu.formatted_address + '<br>DO. ' + reservation.do.formatted_address,
                    }
                })
            }

            ////


            

            if(data.length > 0) {
                 axiosApi.post('mail',data).then(response =>{
                    console.log(response);
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    console.log(e);
                }) 
            }

          

       
        },
        sendSMS(resId){
            let reservation = this.reservation
            let th = this;
            let data = [];

            // Confirmation sms
           

            if(this.reservation.confirmationSMS.confirmation.passenger.send) {
                data.push({
                    smsType: 'confirmation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.phone,
                    text: 'Reservation #' + reservation.readableId + ' created'
                    
                })
            }

            if(this.reservation.confirmationSMS.confirmation.owner.send) {
                data.push({
                    smsType: 'confirmation',
                    recipientType: 'owner',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.companyProfile.smsPhoneTarget,
                    text: 'Reservation #' + reservation.readableId + ' created',
                })
            }




            // Receipt SMS

            if(this.reservation.confirmationSMS.receipt.passenger.send) {
                data.push({
                    smsType: 'receipt',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.phone,
                    text: 'Reservation #' + reservation.readableId + ' created',
                })
            }

            // Driver assignation SMS

            if(this.reservation.confirmationSMS.driverAssignation.passenger.send) {
                data.push({
                    smsType: 'driver assignation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.phone,
                    text: 'Reservation #' + reservation.readableId + ' created',
                })
            }

            if(this.reservation.confirmationSMS.driverAssignation.owner.send) {
                data.push({
                    smsType: 'driver assignation',
                    recipientType: 'owner',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.companyProfile.smsPhoneTarget,
                    text: 'Reservation #' + reservation.readableId + ' created',
                })
            }


            if(this.reservation.confirmationSMS.driverAssignation.driver.send) {
                data.push({
                    smsType: 'driver assignation',
                    recipientType: 'driver',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.assignedDriver,
                    text: 'Reservation #' + reservation.readableId + ' created',
                })
            }


            // Quota SMS

            if(this.reservation.confirmationSMS.quotation.passenger.send) {
                data.push({
                    smsType: 'quotation',
                    recipientType: 'passenger',
                    reservation: resId,
                    user: th.user.data.uid,
                    to: reservation.passenger.phone,
                    text: 'Quotation #' + reservation.readableId + ' created',
                    
                })
            }

            ////


            console.log(data);

            if(data.length > 0) {
                axiosApi.post('sms',data).then(response =>{
                    console.log(response);
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    console.log(e);
                }) 
            }

          

       
        },
        async validateStep(step){
        
            switch(step) {
                case 'general':
                    let companyProfile,vehicleType,qty,lugagge ;
                    companyProfile = this.$v.reservation.companyProfile.$invalid;
                    
                    vehicleType = this.$v.reservation.vehicleType.$invalid;
                    qty = this.$v.reservation.passenger.qty.$invalid;
                    lugagge = this.$v.reservation.passenger.luggage.$invalid;
                    this.tabs.general.errors = 0 ;
                    companyProfile ? this.tabs.general.errors++ : false;
                   
                    vehicleType ? this.tabs.general.errors++ : false;
                    qty ? this.tabs.general.errors++ : false;
                    lugagge ? this.tabs.general.errors++ : false;
                    
                    return !companyProfile  && !vehicleType && !qty && !lugagge ? true : false ;
                
                case 'date-time-location':
                    let d,e,f,jobType ;
                    d = this.$v.reservation.day.$invalid;
                    e = this.$v.reservation.pu.formatted_address.$invalid;
                    f = this.$v.reservation.do.formatted_address.$invalid;
                    jobType = this.$v.reservation.jobType.$invalid;
                    this.tabs.dateTimeLocation.errors = 0;
                    d ? this.tabs.dateTimeLocation.errors++ : false;
                    e ? this.tabs.dateTimeLocation.errors++ : false;
                    f ? this.tabs.dateTimeLocation.errors++ : false;
                    jobType ? this.tabs.dateTimeLocation.errors++ : false;
                    
                    return !d && !e && !jobType ? true : false ;

                case 'dispatchment-info':
                    let reservationStatus;
                    reservationStatus = this.$v.reservation.reservationStatus.$invalid;
                    this.tabs.dispatchmentInfo.errors = 0;
                    reservationStatus ? this.tabs.dispatchmentInfo.errors++ : false;
                    return  !reservationStatus ? true : false ;

                case 'addons':
                    this.tabs.addons.errors = 0;
                    return true
                    
                case 'rates-and-taxes':
                    this.tabs.ratesAndTaxes.errors = 0;
                    return true
                case 'passenger':
                    let passengerQty = this.$v.reservation.passengers.$invalid
                    

                    this.tabs.passenger.errors = 0;
                    
                    passengerQty ? this.tabs.passenger.errors++ : false

                    
                    let validPassenger = !passengerQty ? true : false ;
                    return validPassenger
                    break;
                case 'payment-method':
                    console.log('this.payment.gateway', this.payment.gateway)
                    this.tabs.paymentMethod.errors = 0;
                    
                    if(this.payment.paymentMethod) {
                        switch(this.payment.paymentMethod) {
                                case 'credit-card':
                                    
                                    let cardNumber,cardName,cardCvv,cardMonth,cardYear,billZip,billingAddress,merchantAccount ;
                                   switch(this.payment.gateway) {
                                       case 'Paypal PayFlow Pro':
                                           cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                                           cardName = this.$v.payment.cardFields.cardName.$invalid;
                                           cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                                           cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                                           cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                                           billZip = this.$v.payment.cardFields.billZip.$invalid;
                                           billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;
                                           merchantAccount = this.$v.payment.merchantAccount.$invalid;

                                           cardNumber ? this.tabs.paymentMethod.errors++ : false;
                                           cardName ? this.tabs.paymentMethod.errors++ : false;
                                           cardCvv ? this.tabs.paymentMethod.errors++ : false;
                                           cardMonth ? this.tabs.paymentMethod.errors++ : false;
                                           cardYear ? this.tabs.paymentMethod.errors++ : false;
                                           billZip ? this.tabs.paymentMethod.errors++ : false;
                                           billingAddress ? this.tabs.paymentMethod.errors++ : false;
                                           merchantAccount ? this.tabs.paymentMethod.errors++ : false;


                                           return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && !merchantAccount ? true : false ;
                                       case 'Stripe':
                                           if(this.payment.collect) {
                                            
                                               if(this.payment.elements) {
                                                   console.log('Stripe if')
                                                   let submitElements = await this.payment.elements.submit()
                                                   console.log('submitElements',submitElements)
                                                   if(submitElements.error) {
                                                       this.tabs.paymentMethod.errors++
                                                       return false
                                                   } else {
                                                       return true
                                                   }
                                               } else {
                                                   console.log('Stripe else')
                                                   //// Price not generated
                                                   //this.tabs.paymentMethod.errors++
                                                   return true
                                               }
                                           } else {
                                                return true
                                           }
                                           

                                       default:
                                           cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                                           cardName = this.$v.payment.cardFields.cardName.$invalid;
                                           cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                                           cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                                           cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                                           billZip = this.$v.payment.cardFields.billZip.$invalid;
                                           billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;
                                           merchantAccount = this.$v.payment.merchantAccount.$invalid;
    
                                           cardNumber ? this.tabs.paymentMethod.errors++ : false;
                                           cardName ? this.tabs.paymentMethod.errors++ : false;
                                           cardCvv ? this.tabs.paymentMethod.errors++ : false;
                                           cardMonth ? this.tabs.paymentMethod.errors++ : false;
                                           cardYear ? this.tabs.paymentMethod.errors++ : false;
                                           billZip ? this.tabs.paymentMethod.errors++ : false;
                                           billingAddress ? this.tabs.paymentMethod.errors++ : false;
                                           merchantAccount ? this.tabs.paymentMethod.errors++ : false;
    
    
                                           return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && !merchantAccount ? true : false ;
                                           break;

                                   }
                                    
                                case 'cash':
                                    let paidAmount;
                                    paidAmount = this.$v.payment.cash.paidAmount.$invalid;

                                    paidAmount ? this.tabs.paymentMethod.errors++ : false;
                                    return !paidAmount ? true : false ;
                                case 'check':
                                    let companyName,address,routing,account;
                                    companyName = this.$v.payment.check.companyName.$invalid;
                                    address = this.$v.payment.check.address.$invalid;
                                    routing = this.$v.payment.check.routing.$invalid;
                                    account = this.$v.payment.check.account.$invalid;

                                    companyName ? this.tabs.paymentMethod.errors++ : false;
                                    address ? this.tabs.paymentMethod.errors++ : false;
                                    routing ? this.tabs.paymentMethod.errors++ : false;
                                    account ? this.tabs.paymentMethod.errors++ : false;
                                        

                                    return !companyName && !address && !routing && !account && validPassenger ? true : false ;
                                        
                                case 'invoice':
                                    let companyNameInv,addressInv,ein,email;
                                    companyNameInv = this.$v.payment.invoice.companyName.$invalid;
                                    addressInv = this.$v.payment.invoice.address.$invalid;
                                    ein = this.$v.payment.invoice.ein.$invalid;
                                    email = this.$v.payment.invoice.email.$invalid;

                                    companyNameInv ? this.tabs.paymentMethod.errors++ : false;
                                    addressInv ? this.tabs.paymentMethod.errors++ : false;
                                    ein ? this.tabs.paymentMethod.errors++ : false;
                                    email ? this.tabs.paymentMethod.errors++ : false;
                                        

                                    return !companyNameInv && !addressInv && !ein && !email && validPassenger ? true : false ;
                        }
                    } else {
                        console.log('payment method null')
                        let paymentMethod;
                        paymentMethod = this.$v.payment.paymentMethod.$invalid;
                        paymentMethod ? this.tabs.paymentMethod.errors++ : false;
                        return !paymentMethod ? true : false;
                    }        
                    break;
                case 'emails':
                    this.tabs.emails.errors = 0;
                    return true;
                    
            }                    
        },
        buttonContinueOn() {
            if(this.step == "general"){
                return true;
                //return this.firstStepOk == true ? true : false ;
                
            } else if(this.step == "locations") {
                return true;
                //return this.publicacion.fotos.length > 0 ? true : false ;
            }
        },
        getValidationClass (fieldName) {
            //console.log('fieldName', fieldName)
            let field = null;
            if(
                fieldName == 'qty' 
                || fieldName == 'luggage' 
                ) {

                field = this.$v.reservation.passenger[fieldName]
            } else if(fieldName == 'firstName' 
                || fieldName == 'lastName' 
                || fieldName == 'email' 
                || fieldName == 'phone') {

                field = this.$v.passenger[fieldName]
            } else if(fieldName == 'dialog.email' || fieldName == 'dialog.firstName' || fieldName == 'dialog.lastName' || fieldName == 'dialog.phone') {
                let fieldSplit = fieldName.split('dialog.')[1];
                field = this.$v.newClientPassengerDialog[fieldSplit];

            } else if(fieldName == 'newClient.type' || fieldName == 'newClient.willBePassenger' || fieldName == 'newClient.name' || fieldName == 'newClient.email' || fieldName == 'newClient.firstName' || fieldName == 'newClient.lastName' || fieldName == 'newClient.phone') {
                //let fieldSplit = fieldName.split('newClient.')[1];
                //field = this.$v.newClient[fieldSplit];


                let fieldSplit = fieldName.split('newClient.')[1];

                let clientExsits = {
                    $invalid: false,
                    $dirty: false
                }

                if(fieldName == 'newClient.email') {
                    clientExsits.$invalid = this.clientExsits
                    clientExsits.$dirty = true
                }

                let specialValidation = false
                if(fieldName == 'newClient.email' && this.userCheck) {

                    if(this.newClient.type === 'individual' && !this.isPassenger){
                        clientExsits.$invalid = true
                        clientExsits.$dirty = true
                        specialValidation = true
    
                    } else if(this.newClient.type === 'corporate' && !this.isClient) {
                        clientExsits.$invalid = true
                        clientExsits.$dirty = true
                        specialValidation = true
                    }
                }

                field = this.clientExsits || specialValidation ? clientExsits : this.$v.newClient[fieldSplit];


            } else if(fieldName == 'newPassengerAddition.email' || fieldName == 'newPassengerAddition.firstName' || fieldName == 'newPassengerAddition.lastName' || fieldName == 'newPassengerAddition.phone') {
                let fieldSplit = fieldName.split('newPassengerAddition.')[1];
                field = this.$v.newPassengerAddition[fieldSplit];

            } else if(
                fieldName == 'passengers'
            ) {
                field = this.$v.reservation.passengers
            } else if (
                 fieldName == 'cardNumber' 
                || fieldName == 'cardName' 
                || fieldName == 'cardCvv' 
                || fieldName == 'cardMonth' 
                || fieldName == 'cardYear' 
                || fieldName == 'billZip' 
                || fieldName == 'billingAddress' 
            ) {
                field = this.$v.payment.cardFields[fieldName]
            } else if(
                fieldName == 'check.companyName' 
                || fieldName == 'check.address' 
                || fieldName == 'check.routing' 
                || fieldName == 'check.account' 
            ){
                let fieldSplit = fieldName.split('check.')[1];
                
                field = this.$v.payment.check[fieldSplit]
            } else if(
                fieldName == 'invoice.companyName' 
                || fieldName == 'invoice.address' 
                || fieldName == 'invoice.ein' 
                || fieldName == 'invoice.email' 
            ){
                let fieldSplit = fieldName.split('invoice.')[1];
                field = this.$v.payment.invoice[fieldSplit]
            } else if(fieldName == 'paidAmount' ){
                field = this.$v.payment.cash[fieldName]
            } else if(fieldName == 'merchantAccount'){
                field = this.$v.payment[fieldName]
            } else {
                field = this.$v.reservation[fieldName]
            }
            
            //console.log('field', field.$invalid && field.$dirty)
           
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm () {
            this.$v.$reset()
            // this.email = null
            // this.password = null
            // this.displayName = null
            // this.role = null
            
        },
        validateReservation () {
            this.$v.$touch()

            if (!this.$v.$invalid) {
               // Save reservation
               console.log('Valid');
            }
        },
        inputWithValue(index,value){
            console.log('Input with value');
            console.log(value);
            let incomingIndex = this.labelWrittenItems.indexOf(index);
            if(value.length > 0) {
                if (incomingIndex > -1) {

                } else {
                    this.labelWrittenItems.push(index) ;
                }
                
                //this.focusedItem = index ;
            } else {
                
                if (incomingIndex > -1) {
                    this.labelWrittenItems.splice(incomingIndex, 1);
                }
            }
        },
        unfocusLocation(index) {
            //this.labelActiveItems[index].focused = null ;
            let incomingIndex = this.labelFocusedItems.indexOf(index);
            if (incomingIndex > -1) {
                this.labelFocusedItems.splice(incomingIndex, 1);
            }
        },
        focusedLocation(index){
            console.log(index);
            console.log('focusedLocation')
            this.labelFocusedItems.push(index) ;
        },
        autocompleteUser(index,address) {
            console.log(index)
            this.activeStopIndex = index;
            
            let th = this;
            setTimeout(function(){
                console.log(address.value);
                th.actualStopAddress = address.value ;
                //th.reservation.waypoints[index].formatted_address = address.value
            },0)

        },
        async getCardToken(){
            let th = this
            let publicKey
            for(let i = 0 ; i < this.merchantAccounts.length ; i++) {
                if(this.merchantAccounts[i].id == this.payment.merchantAccount) {
                    publicKey = this.merchantAccounts[i].public
                }   
            }
            var stripe = await loadStripe(publicKey);
            console.log('stripe', stripe)
            return new Promise((resolve, reject) => {
                // let options = {
                //     value: {
                //         number:  th.payment.cardFields.cardNumber.replace(/\s/g, ""),
                //         exp_month:  th.payment.cardFields.cardMonth,
                //         exp_year: th.payment.cardFields.cardYear,
                //         cvc: th.payment.cardFields.cardCvv,
                //     },
                // }

                var elements = stripe.elements();
                console.log(cardElement)
                // stripe.createToken(cardNumber)
                // stripe.createToken(cardElement).then(response =>{
                //     console.log(response);
                //     resolve()

                // }).catch(e => {
                
                   
                //     console.log(e);
                //     reject()
                // }) 
            })
        },
        async add(){
            let th = this;
            this.isBusy = true;
            this.busyMessage = "Saving reservation"
            let paymentResult 
            let createReservation = false
            return new Promise(async (resolve, reject) => {
                let data = {
                    reservation: th.reservation,
                    paymentData: th.payment,
                    user: th.user.data.uid,
                    collect: th.payment.collect,
                    creator: {
                        email: th.user.data.email,
                        role: th.user.data.activeRole,
                        displayName: th.user.data.displayName,
                    },
                    transaction: {}
                } ;

                let paymentType 

                switch(th.payment.gateway) {
                    case 'Paypal PayFlow Pro':
                        paymentType = 'paypal'
                        createReservation = true
                        break;
                    case 'Stripe':
                        paymentType = 'stripe'
                        
                        if(this.payment.collect) {

                            paymentResult = await this.submitStripePayment()
                            this.payment.paymentIntentObject = paymentResult
                        }

                        break;
                }

                switch (th.payment.paymentMethod) {
                    case 'cash':
                        data.transaction = {
                            amount : th.payment.cash.paidAmount,
                            type: 'cash'
                        }
                        break;
                    case 'credit-card':
                        data.transaction = {
                            cardFields: th.payment.cardFields,
                            amount: th.cardToPayAmount,
                            type: paymentType
                        }

                        break;

                }

                if(this.payment.paymentIntentObject) {
                    console.log(this.payment.paymentIntentObject)

                    if(this.payment.paymentIntentObject.status == 500) {
                        console.log('Paymnet failed')
                        this.tabs.paymentMethod.errors++

                        resolve(this.payment.paymentIntentObject)
                    }

                    if(this.payment.paymentIntentObject.status == 200) {
                        
                        createReservation = true

                    }
                }
                
                if(!this.payment.collect) {
                    createReservation = true
                }

                if(createReservation) {
                    console.log('Create reservation')
                    if(this.payment.paymentIntentObject) {
                        this.payment.paymentIntentObject = this.payment.paymentIntentObject.data
                    }
                    axiosApi.post('reservation/create',data).then(response =>{
                        console.log(response);
                    
                        resolve(response)
                    }).catch(e => {
                
                        console.log(e);
                        reject()
                    })
                   
                    
                } else {
                    resolve()
                }

                
                
                
            })
           
        },
        showStatusModal(){
            this.showDialog = true;
        },
        setDescription(description) {
            this.description = description;
        },
        saveWaypoint(index) {
            //this.reservation.waypoints[this.activeStopIndex] = this.waypointsPack[this.activeStopIndex] ;
        },
        removeWaypoint(key){
           console.log(key);
            this.reservation.waypoints.splice(key, 1);
        },
        addStop(){ 
            // let waypoint = {
            //     stopover: true,
            //     location: null
            // }
            // this.reservation.waypoints.push({
            //     stopover: true,
            //     location: null
            // })
            //this.waypointsPack.push({});
            this.reservation.waypoints.push({});
            
        },
        setWP(place) {
            console.log('Place changed');
            
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            let wp = {};
            wp.postalCode = postal;
            wp.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };

            wp.stop = {
                        location: place.geometry.location.lat() + ',' + place.geometry.location.lng(),
                        stopover: true
                    }

            wp.formatted_address = this.actualStopAddress

            // let wp = {
            //         //formatted_address: place.formatted_address,
            //         postalCode: postal,
            //         coordinates: {
            //             lat: place.geometry.location.lat(),
            //             lng: place.geometry.location.lng()
            //         },
            //         stop: {
            //             location: place.geometry.location.lat() + ',' + place.geometry.location.lng(),
            //             stopover: true
            //         }
                    
            // }

            //this.reservation.waypoints[this.activeStopIndex] = wp;
            this.reservation.waypoints.splice(this.activeStopIndex, 1, wp);
           

            
        },
        getSelectedAddress(type,address) {
           let th = this;
           setTimeout(function(){
               console.log(address);
                th.reservation[type].formatted_address = address.value
           },0)
            
        },
        setPU(place) {
            console.log(place)
            //this.reservation.pu = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }

            this.reservation.pu.postalCode = postal;
            this.reservation.pu.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };

            // this.reservation.pu = {
            //         //formatted_address: place.formatted_address,
            //         postalCode: postal,
            //         coordinates: {
            //             lat: place.geometry.location.lat(),
            //             lng: place.geometry.location.lng()
            //         }
            // }

        },
        setDO(place) {
            console.log(place)
            //this.reservation.do = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }

            this.reservation.do.postalCode = postal;
            this.reservation.do.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };


            // this.reservation.do = {
            //         formatted_address: place.formatted_address,
            //         postalCode: postal,
            //         coordinates: {
            //             lat: place.geometry.location.lat(),
            //             lng: place.geometry.location.lng()
            //         }
            // }

        },
        updatedMiles(miles) {
            this.reservation.miles = miles;
        },
        overviewPath(path) {
            this.feeCalculatorData.overviewPath = path;
            console.log(path)
        },
        async getLastIdRef() {
            let th = this;

            return new Promise((resolve, reject) => {
                
                this.lastIdRef.onSnapshot((querySnapshot) => {
                    this.lastId = null;
                    querySnapshot.forEach((doc) => {
    
                        let dataToSave = doc.data().lastId;
                        //dataToSave.id = doc.id ;
                        this.lastId = dataToSave;
                        
                    });
                    resolve()
                });
            })



        },
        async getVehicleTypes() {
            return new Promise((resolve, reject) => {
                
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').onSnapshot((querySnapshot) => {
                    this.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        let counter = 0;
                        if(dataToSave.rates.addons){
                            for (let i = 0; i < dataToSave.rates.addons.length; i++) {
                                if(dataToSave.rates.addons[i].enabled && dataToSave.rates.addons[i].price) {
                                    counter++;
                                }
                                
                            }
                        }
                        
                        counter > 0 ? dataToSave.rates.addonsAvailable = true : dataToSave.rates.addonsAvailable = false
                        this.vehicleTypes.push(dataToSave);
                        
                    });
                    resolve()
                });
            })

        },
        runCompanyProfileDefault() {
            for (let i = 0; i <  this.companyProfiles.length; i++) {
                if(this.companyProfiles[i].default) {
                    this.selectedData.companyProfile = this.companyProfiles[i].id
                    this.reservation.companyProfile = this.companyProfiles[i]
                    this.updateTime()
                    this.flowTimeZone();
                }
                
            }
        },
        runMerchantDefault() {
            for (let i = 0; i <  this.merchantAccounts.length; i++) {
                if(this.merchantAccounts[i].default) {
                    this.payment.merchantAccount = this.merchantAccounts[i].id
                    this.payment.gateway = this.merchantAccounts[i].gateway
                }
                
            }
        },
        async getCompanyProfiles() {

            return new Promise((resolve, reject) => {
                
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                    this.companyProfiles = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        
                        this.companyProfiles.push(dataToSave);
                        
                    });
                    for (let i = 0; i <  this.companyProfiles.length; i++) {
                        if(this.companyProfiles[i].default) {
                            this.selectedData.companyProfile = this.companyProfiles[i].id
                            this.reservation.companyProfile = this.companyProfiles[i]
                            this.updateTime()
                            this.flowTimeZone();
                        }
                        
                    }
                    resolve()
    
                });
            })


        },
        getAddons() {
            return new Promise((resolve, reject) => {
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').onSnapshot((querySnapshot) => {
                    this.addOns = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.addOns.push(dataToSave);
                        
                    });
                    resolve()
                });
                
            })
        },
        getServiceTypes() {
            return new Promise((resolve, reject) => {
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('service-types').onSnapshot((querySnapshot) => {
                    this.serviceTypes = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        if(dataToSave.vehicleType && dataToSave.vehicleType.length > 0) {
                            this.serviceTypes.push(dataToSave);
                        }
                        

                    });
                    resolve()
                });
                
            })

        },
        getJobTypes() {

            return new Promise((resolve, reject) => {
                
                this.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                    this.jobTypes = [];
                    querySnapshot.forEach((doc) => {
    
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.jobTypes.push(dataToSave);
                        
                    });
                    resolve()
    
                });
            })

        },
        getStatuses() {
            return new Promise((resolve, reject) => {
                this.statusesRef.orderBy('name').onSnapshot((querySnapshot) => {
                    this.reservationStatuses =  []
                    this.driverStatuses =  []
                    this.paymentStatuses =  []
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        switch(dataToSave.type) {
                            case 'reservation':
                                this.reservationStatuses.push(dataToSave);
                                break;
                            case 'driver':
                                this.driverStatuses.push(dataToSave);
                                break;
                            case 'payment':
                                this.paymentStatuses.push(dataToSave);
                                break;
                            
                        }
                        
                        
                    });
                    resolve()
                })

            })



        },
        async getClients() {
            let th = this
            return new Promise((resolve, reject) => {
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('clients').onSnapshot((querySnapshot) => {
                th.clients = [];
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    
                    th.clients.push(dataToSave);
                });

            
                th.total = th.clients.length;
                resolve()
                })
            })
        },
        async getPassengers() {
            let th = this ;
            return new Promise(async (resolve, reject) => {
                
                for (let i = 0; i < th.clients.length; i++) {
                    th.clients[i].passengers = []
                    let passengers = await this.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('clients').doc(th.clients[i].id).collection('passengers').get()
                    passengers.forEach(doc => {
                        let dataToSave = doc.data()
                        dataToSave.id = doc.id
                        th.clients[i].passengers.push(dataToSave)
                    })
                }
                resolve()
            })
        },
        async obtainViewResources() {
            // TODO: obtainViewResources()
            this.isBusy = true
            //await this.getClients()
            //await this.getPassengers()
            await this.getLastIdRef()
            //await this.getDrivers()
            //await this.getDispatchers()
            //await this.getServiceTypes()
            //await this.getVehicleTypes()
            //await this.getCompanyProfiles()
            this.runCompanyProfileDefault()
            //await this.getMerchantAccounts()
            this.runMerchantDefault()
            //await this.getJobTypes()
            //await this.getStatuses()
            this.isEditing = false 

            this.isBusy = false

           
            
        }
    },
    computed: {
        getTabLabel() {
            let label = null

            if(this.$refs.mainTabs) {

                switch(this.$refs.mainTabs.activeTab){ 
                    case 'date-time-location':
                        label = 'Datetime & location'
                        break;
                    case 'general':
                        label = 'General'
                        break;
                    case 'dispatchment-info':
                        label = 'Dispatchment info'
                        break;
                    case 'addons':
                        label = 'Addons'
                        break;
                    case 'rates-and-taxes':
                         label = 'Rates and taxes'
                        break;
                    case 'passenger':
                        label = 'Passenger'
                        break;
                    case 'payment-method':
                        label = 'Payment method'
                        break;
                    case 'emails':
                        label = 'Emails'
                        break;
                    case 'sms':
                        label = 'SMS'
                        break;
                    
                }
            } else {
                label = 'Date, time & location'
            }

            console.log(label)


            return label
        },
        gatewayName() {
            return this.payment.gateway
        },
        availableClientPassengers() {
            let availablePassengers = null

            if(this.reservation.client && this.reservation.client.passengers) {
                let alreadySelectedContacts = 0
                for (let j = 0; j < this.reservation.passengers.length; j++) {
                    if(!this.reservation.passengers[j].new) {
                        alreadySelectedContacts++
                    }
                    
                }

                availablePassengers = this.reservation.client.passengers.length - alreadySelectedContacts
            }
            
            return availablePassengers
        },
        clientText() {
            let text = ''
            if(this.preselected.client){
                text = `${this.preselected.client.name} ${this.preselected.client.phone}`
            }
            return text
        },
        passengers(){
            let passengers = []
            // for (let i = 0; i < this.clients.length; i++) {
            //     for (let j = 0; j < this.clients[i].passengers.length; j++) {
            //         let alreadySelected = false
            //         for (let k = 0; k < this.reservation.passengers.length; k++) {
            //             if(this.reservation.passengers[k].id === this.clients[i].passengers[j].id) {
            //                 alreadySelected = true
            //             }
                        
            //         }

            //         if(!alreadySelected) {
            //             let passengerToSave = this.clients[i].passengers[j]
            //             passengerToSave.clientName = this.clients[i].name
            //             passengerToSave.clientPhone = this.clients[i].phone
            //             // delete passengerToSave.client.passengers
            //             passengers.push(passengerToSave)
            //         }
                    
            //     }
                
            // }

            return passengers
        },
        minCardMonth () {
            if (this.payment.cardFields.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
        origin() {
            if (!this.reservation.pu) return null;
            return { query: this.reservation.pu.coordinates.lat + ',' + this.reservation.pu.coordinates.lng };
        },
        destination() {
            if (!this.reservation.do) return null;
            return { query: this.reservation.do.coordinates.lat + ',' + this.reservation.do.coordinates.lng };
        },
        computedWaypoints() {
            if (this.reservation.waypoints.length == 0 ) return null;
            const total = [];
            console.log('Computed waypoints');
            console.log(this.reservation.waypoints);
            this.reservation.waypoints.forEach((waypoint) => {
                if(!waypoint.stop){
                    //total.push(null);
                } else {
                    total.push({
                        stopover:true,
                        location: !waypoint.stop ? null : waypoint.stop.location
                    })
                }
                
            });
            return total;
            
        }, 
        dialogDimensions () {
            console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
        dialogWidth() {
            return this.$store.state.dialogWidth
        },
        user() {
            return this.$store.state.user;
        },

        clients() {
            return this.$store.state.clients;
        },
        drivers() {
            return this.$store.state.drivers;
        },
        dispatchers() {
            return this.$store.state.dispatchers;
        },
        serviceTypes() {
            return this.$store.state.serviceTypes;
        },
        vehicleTypes() {
            return this.$store.state.vehicleTypes;
        },
        companyProfiles() {
            return this.$store.state.companyProfiles;
        },
        jobTypes() {
            return this.$store.state.jobTypes;
        },
        merchantAccounts() {
            return this.$store.state.merchantAccounts;
        },
        reservationStatuses() {
            return this.$store.state.reservationStatuses;
        },
        driverStatuses() {
            return this.$store.state.driverStatuses;
        },
        paymentStatuses() {
            return this.$store.state.paymentStatuses;
        }

        
    
        
       
    },
   
    created () {
        let th = this ;
        // this.getBraintreeToken().then(function(){
        //     th.createBT()
        // });
        // let origin = window.location.origin
        // let routeList = th.$router.matcher.getRoutes()

        // let finalRoute = routeList.filter((route) => {
        //     return route.name == 'Reservations'
        // })

        // let return_url = `${origin}${finalRoute[0].path}`
        // console.log(return_url)

        window.addEventListener("resize", this.myEventHandler);
        window.addEventListener('scroll', this.positionMenu)
        
        this.obtainViewResources()        
        

    },
    
    validations: {
        //paymentMethod: null,
        
        newPassengerAddition: {
            email: {
                email
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            phone: {
                required
            }
        },
        newClient: {
            type: {
                required
            },
            name: {
                required: requiredIf(function(){
                    return this.newClient.type === 'corporate' ? true : false
                })
            },
            email: {
                email,
                required
            },
            firstName: {
                required: requiredIf(function(){
                    return this.newClient.type === 'individual' ? true : false
                })
            },
            lastName: {
                required: requiredIf(function(){
                    return this.newClient.type === 'individual' ? true : false
                })
            },
            phone: {
                required
            },
            willBePassenger: {
                required: requiredIf(function(){
                    return this.newClient.type === 'individual' ? true : false
                })
            }
            
        },
        newClientPassengerDialog: {
            email: {
                email,
                required
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            phone: {
                required
            }
            
        },
        passenger: {
            firstName: {
                required
            },
            lastName:{
                //required
            },
            email:{
                //required,
                email
            },
            phone:{
                required
            }
        },
        payment: {
            merchantAccount: {
                required: requiredIf(function(){
                     return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                })
            },
            cardFields: {
                    cardName: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    },
                    cardNumber: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            // 

                        })
                    },
                    cardMonth: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    },
                    cardYear: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    },
                    cardCvv: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    },
                    billZip: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    },
                    billingAddress: {
                        required: requiredIf(function(payment){
                            return this.payment.paymentMethod == 'credit-card' && this.payment.gateway == 'Paypal PayFlow Pro' ? true : false
                            // return !this.payment.collect && this.payment.paymentMethod == 'credit-card' ? false : true ;
                            

                        })
                    }
                
            },
            paymentMethod: {
                required
            },
            cash: {
                paidAmount: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'cash' ? false : true ;
                    })
                }
            },
            invoice: {
                companyName: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'invoice' ? false : true ;

                    })
                },
                address: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'invoice' ? false : true ;

                    })
                },
                ein: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'invoice' ? false : true ;

                    })
                },
                email: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'invoice' ? false : true ;

                    })
                }
            },
            check: {
                companyName: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'check' ? false : true ;

                    })
                },
                address: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'check' ? false : true ;

                    })
                },
                routing: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'check' ? false : true ;

                    })
                },
                account: {
                    required: requiredIf(function(payment){
                        return !this.payment.collect && this.payment.paymentMethod == 'check' ? false : true ;

                    })
                }
            },
        },
        reservation: {
            
            passengers: {
                required
            },
            passenger: {
                qty: {
                    required,
                    between (value) {
                        return between(1, this.selectedData.passengers)(value)
                    }
                },
                luggage: {
                    required,
                    between (value) {
                        return between(0, this.selectedData.luggage)(value)
                    }
                },
                
            },
            reservationStatus: {
                required
            },
            jobType: {
                required
            },
            companyProfile: {
                required
            },
            vehicleType: {
                required
            },
            addOns: {
                //required
            },
            dispatcher: {
                //required
            },
            day: {
                required
            },
            pu: {
                formatted_address: {
                    required
                }
            },
            do: {
                formatted_address: {
                    required
                }
            },
            flightNumber: {
                required: requiredIf(function(reservation){
                    
                    if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                        return true
                    } else {
                        return false
                    }
                    
                }),
                serverError () { return this.serverError }
            },
            flightAirline: {
                required: requiredIf(function(reservation){
                    
                    if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                        return true
                    } else {
                        return false
                    }
                    
                })
            },
            
        }
        
       
        
    },
    beforeRouteLeave(to, from, next) {
   
        if (this.isEditing && !this.confirmLeave) {
            this.confirmDialog = true;
            console.log(to);
            this.goTo = to;
            return 
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
    
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        window.removeEventListener('scroll', this.positionMenu)
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
    },
    beforeDestroy() {
       window.removeEventListener("beforeunload", this.preventNav);
    },
}
</script>  

<style lang="scss">
  .badge {
    width: 19px;
    height: 19px;
    line-height:19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }

  .badge.md-primary {
      background-color: rgb(119, 207, 119);
  }

  .badge.md-primary .md-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    font-size: 12px!important;
    color:white!important;

  }

  .text-decoration-line-through .md-table-head-label {
      text-decoration: line-through;
  }


  /*--------------------
    SHARED VARIABLES
    --------------------*/
    $red: #E91E63;
    $blue: #3F51B5;
    $grey: #EAE8E9;
    $grey2: #f3f3f3;
    $white: #FFF;

    /*--------------------
    GENERAL
    --------------------*/

    *,
    *:before,
    *:after {
    box-sizing: inherit;
    }

   

    #cardForm {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    /*--------------------
    PANEL FORM
    --------------------*/

   

    .textfield--float-label {
    width: 100%;
    display: inline-block;
    padding-right: 5px;
    }

    .hosted-field--label {
    transform: translateY(0.4em);
    font-size: 1.125em;
    line-height: 32px;
    transition: all .15s ease-out;
    display: block;
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 0.5em;
    &.label-float {
        text-overflow: ellipsis;
        color: #2196F3;
        transition: all .15s ease-out
    }
    &.filled {
        @extend .label-float;
        color: rgba(0, 0, 0, .54);
    }
    &.invalid {
        @extend .label-float;
        color: #F44336;
    }
    }

    span.icon {
    position: relative;
    top: 0.2em;
    margin-right: 0.2em;
    }

    svg {
    fill: #333;
    }

    .hosted-field {
    height: 32px;
    margin-bottom: 1em;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    }

    .pay-button {
    background: #E91E63;
    color: #fff;
    margin: 0 auto;
    border: 0;
    border-radius: 3px;
    padding: 1em 3em;
    font-size: 1em;
    text-transform: uppercase;
    box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 2px 2px rgba(0, 0, 0, .2);
    }


    /*--------------------
    BT HOSTED FIELDS SPECIFIC 
    --------------------*/

    .braintree-hosted-fields-focused {
    border-bottom: 2px solid $blue;
    transition: all 200ms ease;
    }

    .braintree-hosted-fields-invalid {
    border-bottom: 2px solid $red;
    transition: all 200ms ease;
    }


    /*---------------------
    Media Queries
    ----------------------*/

    @media (max-width: 600px) {
    html {
        overflow: auto;
    }
    #cardForm {
        height: auto;
        margin: 2em;
        font-size: 13px;
    }
    .panel {
        width: 100%;
    }
    .textfield--float-label {
        width: 100%;
        float: none;
        display: inline-block;
    }
    .pay-button {
        width: 100%;
    }
    }

  

  
</style>